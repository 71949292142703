import React, {useState} from 'react'
import { Link } from 'react-router-dom';

import './NavBarHome.css'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {auth} from '../services/firebase'

const NavBarHome = ({setActive, active} ) => {

    const [dadosUsuarios, setDadosUsuarios] = useState(JSON.parse(localStorage.getItem("usuario")));

    function handleGoogleSignIn() {
      const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider)
          .then((result) =>
          {
            localStorage.setItem("usuario", JSON.stringify(result.user));
            setDadosUsuarios(result.user)
          }
        )
            .catch((error) => {
              alert("Não foi possível realizar o login ou o e-mail selecionado não é autorizado, por favor tente novamente.")
          console.log(error)
        })
      }

      function handleSair() {
        localStorage.removeItem("usuario")
        setDadosUsuarios(null)
    }

  return (
    <header>
        <div className='container'>
            <div className='logo'>
                <h3>o<span>Facilitador</span>.pro</h3>
            </div>
            <div class="overlay"></div>

            <div className='hamburger-menu' onClick={()=>{
                setActive(!active)
            }}>
                <div className='bar'>
                </div>
            </div>
            <div className='links'>
                <ul>
                    <li>
                        <a href='#servicos'>
                             <span className='anchor'>Serviços</span>
                        </a>
                        
                    </li>
                    <li>
                        <a href='#contato'>
                             <span className='anchor'>Contato</span>
                        </a>
                    </li>
                    <li>
                    {dadosUsuarios &&  <Link to="areadocliente/xmlnfe">
                             <span className='anchor'>Área do Cliente</span>
                        </Link>}
                    </li>
                    <li>
                    {!dadosUsuarios && <div className='fazerlogin'>
                             <button className="btn" onClick={handleGoogleSignIn} > <AccountCircleIcon className='userIcon'/> Fazer Login</button>
                        </div>}
                    </li>
                    <li>
                        <div className='infoUsuarioHome'>
                        {dadosUsuarios && <>
                        <img style={{marginLeft: "3.5rem"}} src={dadosUsuarios.photoURL} alt="foto usuario" className='userimg' /> 
                        <ExitToAppIcon id="sair" onClick={handleSair} style={{fontSize: "50px"}} /></>}
                        </div>
                    </li>
                </ul>
            </div>

            
        </div>
    </header>
  )
}

export default NavBarHome