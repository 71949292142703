import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

export default function Tags({ allData, setSelectedSimNao }) {
  const todosTipos = ['Não', 'Sim'];

    const handleChange = (event, value) => setSelectedSimNao(value);
  return (
    <Stack spacing={3} sx={{ margin: " 0 "}}>
        <Autocomplete
              onChange={handleChange}
        id="comdesconto"
        options={todosTipos}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Com Desconto"
            fullWidth
          />
        )}
      />
    </Stack>
  );
}
