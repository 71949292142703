const dadosComprovanteOperacaoTitulos = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
     for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){
        case arrayTexto[lin].includes("CPF / CNPJ do pagador:"):
          cpfcnpjPagador = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("CPF/CNPJ do pagador:"):
          cpfcnpjPagador = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Agência:"):
          agencia = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Conta:"):
          conta = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Nome do favorecido:"):
          nomeRecebedor = arrayTexto[lin+2] 
          lin += 2
          break;
        case arrayTexto[lin].includes("CPF / CNPJ:"):
          cpfcnpjRecebedor = arrayTexto[lin+2] 
          lin += 2
          break;
        case arrayTexto[lin].includes("CPF/CNPJ:"):
          cpfcnpjRecebedor = arrayTexto[lin+2] 
          lin += 2
          break;
        case arrayTexto[lin].includes("Valor pago:"):
          valor = arrayTexto[lin+2]
          lin += 2
          break;
          case arrayTexto[lin].includes("Pagamento efetuado em"):
            var pegarData = arrayTexto[lin].split(" ")
            doc = pegarData[9]
            dataPag = pegarData[3].replaceAll(".", "/")
            lin += 2
            break;
      }
      if(cpfcnpjPagador != '' && agencia != '' && conta != '' &&
      nomeRecebedor != '' && cpfcnpjRecebedor != '' && valor != '' &&
      dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const dadosComprovanteOperacaoConcessionarias = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
    nomeRecebedor = arrayTexto[index + 5]
    for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){
        case arrayTexto[lin].includes("Agência:"):
          agencia = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Conta:"):
          conta = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Valor pago:"):
          valor = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Pagamento efetuado em"):
          var pegarData = arrayTexto[lin].split(" ")
          dataPag = pegarData[3].replaceAll(".", "/")
          doc = pegarData[9]
          lin += 2
          break;
        }
        if(agencia != '' && conta != '' &&
        nomeRecebedor != '' && valor != '' &&
        dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }    
  }

  const itauDadosComprovanteOperacaoConcessionarias = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
    nomeRecebedor = arrayTexto[index + 2]
    for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){
        case arrayTexto[lin].includes("Agência:"):
          agencia = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Conta:"):
          conta = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Valor do documento:"):
          valor = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Operação efetuada em"):
          var pegarData = arrayTexto[lin].split(" ")
          dataPag = pegarData[3].replaceAll(".", "/")
          doc = pegarData[9].replaceAll(".", "")
          lin += 2
          break;
        }
        if(agencia != '' && conta != '' &&
        nomeRecebedor != '' && valor != '' &&
        dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }    
  }

  const itauDadosComprovanteOperacaoTributosEstaduais = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
    nomeRecebedor = "Tributos Estaduais"
    for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){
        case arrayTexto[lin].includes("Agência:"):
          agencia = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Conta:"):
          conta = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Valor do documento:"):
          valor = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Operação efetuada em"):
          var pegarData = arrayTexto[lin].split(" ")
          dataPag = pegarData[3].replaceAll(".", "/")
          doc = pegarData[9].replaceAll(".", "")
          lin += 2
          break;
        }
        if(agencia != '' && conta != '' &&
        nomeRecebedor != '' && valor != '' &&
        dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }    
  }

  const itauDadosComprovanteOperacaoTributosMunicipais = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
    nomeRecebedor = "Tributos Municipais"
    for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){
        case arrayTexto[lin].includes("Agência:"):
          agencia = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Conta:"):
          conta = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Valor do documento:"):
          valor = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Operação efetuada em"):
          var pegarData = arrayTexto[lin].split(" ")
          dataPag = pegarData[3].replaceAll(".", "/")
          doc = pegarData[9].replaceAll(".", "")
          lin += 2
          break;
        }
        if(agencia != '' && conta != '' &&
        nomeRecebedor != '' && valor != '' &&
        dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }    
  }

  const dadosComprovanteOperacaoTributosEstaduaisMunicipais = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
     nomeRecebedor = arrayTexto[index+4].includes("Tributos Estaduais") ? "Tributos Estaduais" : "Tributos Municipais"
     for(var lin = index; lin < arrayTexto.length; lin ++){
        switch(true){
            case arrayTexto[lin].includes("Agência:"):
              agencia = arrayTexto[lin+2]
              lin += 2
              break;
          case arrayTexto[lin].includes("Conta:"):
            conta = arrayTexto[lin+2]
            lin += 2
            break;
          case arrayTexto[lin].includes("Valor pago:"):
            valor = arrayTexto[lin+2]
            lin += 2
            break;
          case arrayTexto[lin].includes("Pagamento efetuado em"):
            var pegarData = arrayTexto[lin].split(" ")
            dataPag = pegarData[3].replaceAll(".", "/")
            doc = pegarData[9]
            lin += 2
            break;
      }
      if(agencia != '' && conta != '' &&
        nomeRecebedor != '' && valor != '' &&
        dataPag != ''){
          return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
            valor, agencia, conta, 
            cpfcnpjPagador ])
      }
    }
  }

  const dadosComprovanteQR = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
     for(var lin = index; lin < arrayTexto.length; lin ++){
        switch(true){
          case arrayTexto[lin].includes("CPF / CNPJ do pagador:"):
            cpfcnpjPagador = arrayTexto[lin+2]
            lin += 2
            break;
          case arrayTexto[lin].includes("agência/conta:"):
            var pegarConta = arrayTexto[lin+2].split("/")
            agencia = pegarConta[0]
            conta = pegarConta[1]
            lin += 2
            break;
          case arrayTexto[lin].includes("nome do recebedor:"):
            nomeRecebedor = arrayTexto[lin+2] + (arrayTexto[lin+4].includes("CPF / CNPJ do recebedor:") ? "" : arrayTexto[lin+4])
            lin += 2
            break;
          case arrayTexto[lin].includes("CPF / CNPJ do recebedor:"):
            cpfcnpjRecebedor = arrayTexto[lin+2]
            lin += 2
            break;
          case arrayTexto[lin].includes("valor da transação:"):
            valor = "R$ " + arrayTexto[lin+2]
            lin += 2
            break;
          case arrayTexto[lin].includes("Pagamento efetuado em"):
            var pegarData = arrayTexto[lin].split(" ")
            dataPag = pegarData[3]
            lin += 2
            break;
          case arrayTexto[lin].includes("controle"):
            doc = arrayTexto[lin+2]
            lin += 2
            break;
      }
      if(cpfcnpjPagador != '' && agencia != '' && conta != '' &&
      nomeRecebedor != '' && cpfcnpjRecebedor != '' && valor != '' &&
      dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const dadosComprovanteTransferencia = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
     for(var lin = index; lin < arrayTexto.length; lin ++){
        switch(true){
          case arrayTexto[lin].includes("CPF / CNPJ do pagador:"):
            cpfcnpjPagador = arrayTexto[lin+2]
            lin += 1
            break;
          case arrayTexto[lin].includes("agência/conta:"):
            var pegarConta = arrayTexto[lin+2].split("/")
            agencia = pegarConta[0]
            conta = pegarConta[1]
            lin += 1
            break;
          case arrayTexto[lin].includes("nome do recebedor:"):
            nomeRecebedor = arrayTexto[lin+2] + (arrayTexto[lin+4].includes("chave:") ? "" : arrayTexto[lin+4])
            lin += 1
            break;
          case arrayTexto[lin].includes("CPF / CNPJ do recebedor:"):
            cpfcnpjRecebedor = arrayTexto[lin+2]
            lin += 1
            break;
          case arrayTexto[lin].includes("valor:"):
            valor = arrayTexto[lin+2] 
            lin += 1
            break;
          case arrayTexto[lin].includes("data da transferência:"):
            dataPag = arrayTexto[lin+2]
            lin += 1
            break;
          case arrayTexto[lin].includes("controle:"): 
            doc = arrayTexto[lin+1] 
            lin += 1
            break;
      }
      if(cpfcnpjPagador != '' && agencia != '' && conta != '' &&
      nomeRecebedor != '' && cpfcnpjRecebedor != '' && valor != '' &&
      dataPag != '' && doc != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const dadosComprovanteTransferenciaCC = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '', contadorAg = 0, contadorNome = 0;
     for(var lin = index; lin < arrayTexto.length; lin ++){
        switch(true){
            case arrayTexto[lin].includes("Agência:") && contadorAg === 0:
                agencia = arrayTexto[lin+2]
                lin += 2
                break;
            case arrayTexto[lin].includes("Conta:") && contadorAg === 0:
                conta = arrayTexto[lin+2]
                lin += 2
                contadorAg++
                break;
            case arrayTexto[lin].includes("Conta corrente:") && contadorAg === 0:
                conta = arrayTexto[lin+2]
                lin += 2
                contadorAg++
                break;
            case arrayTexto[lin].includes("Agência:") && contadorAg === 1:
                cpfcnpjRecebedor = "Transferência"
                lin += 6
                contadorAg++
                break;
            case arrayTexto[lin].includes("Nome:") && contadorNome === 0:
                contadorNome++
                lin += 2
                break;
            case arrayTexto[lin].includes("Nome da empresa:") && contadorNome === 0:
                contadorNome++
                lin += 2
                break;
            case arrayTexto[lin].includes("Nome:") && contadorNome === 1:
                nomeRecebedor = arrayTexto[lin+2] 
                contadorNome++
                lin += 2
                break;
            case arrayTexto[lin].includes("Valor:"):
                valor = arrayTexto[lin+2]
                lin += 2
                break;
            case arrayTexto[lin].includes("Transferência realizada em"):
                var pegarData = arrayTexto[lin].split(" ")
                dataPag = pegarData[3].replaceAll(".", "/")
                doc = pegarData[9]
                lin += 2
                break;
            case arrayTexto[lin].includes("Transferência efetuada em"):
                var pegarData = arrayTexto[lin].split(" ")
                dataPag = pegarData[3].replaceAll(".", "/")
                doc = pegarData[9].replaceAll(".", "")
                lin += 2
                break;
      } 
      if(agencia != '' && conta != '' &&
      nomeRecebedor != '' && cpfcnpjRecebedor != '' && valor != '' &&
      dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const dadosComprovanteOperacaoGRF = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
     nomeRecebedor = "GRF - Guia de Recolhimento do FGTS"
     for(var lin = index; lin < arrayTexto.length; lin ++){
        switch(true){
            case arrayTexto[lin].includes("Agência:"):
                agencia = arrayTexto[lin+2]
                lin += 2
                break;
            case arrayTexto[lin].includes("Conta:"):
                conta = arrayTexto[lin+2]
                lin += 2
                break;
            case arrayTexto[lin].includes("CNPJ:"):
                cpfcnpjPagador = arrayTexto[lin+2] 
                lin += 2
                break;
            case arrayTexto[lin].includes("Valor Recolhido:"):
                valor = arrayTexto[lin+2]
                lin += 2
                break;
            case arrayTexto[lin].includes("Pagamento realizado em"):
                var pegarData = arrayTexto[lin].split(" ")
                dataPag = pegarData[3].replaceAll(".", "/")
                doc = pegarData[9]
                lin += 2
                break;
      }
      if(agencia != '' && conta != '' &&
      nomeRecebedor != '' && cpfcnpjPagador != '' && valor != '' &&
      dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const itauDadosComprovanteOperacaoGRF = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
     nomeRecebedor = "GRF - Guia de Recolhimento do FGTS"
     for(var lin = index; lin < arrayTexto.length; lin ++){
        switch(true){
            case arrayTexto[lin].includes("Agência:"):
                agencia = arrayTexto[lin+2]
                lin += 2
                break;
            case arrayTexto[lin].includes("Conta:"):
                conta = arrayTexto[lin+2]
                lin += 2
                break;
            case arrayTexto[lin].includes("CNPJ:"):
                cpfcnpjPagador = arrayTexto[lin+2] 
                lin += 2
                break;
            case arrayTexto[lin].includes("Valor Recolhido:"):
                valor = arrayTexto[lin+2]
                lin += 2
                break;
            case arrayTexto[lin].includes("Operação efetuada em"):
                var pegarData = arrayTexto[lin].split(" ")
                dataPag = pegarData[3].replaceAll(".", "/")
                doc = pegarData[9].replaceAll(".", "")
                lin += 2
                break;
      }
      if(agencia != '' && conta != '' &&
      nomeRecebedor != '' && cpfcnpjPagador != '' && valor != '' &&
      dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const dadosComprovanteOperacaoGRRF = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
     nomeRecebedor = "GRRF - Guia de Recolhimento Rescisório FGTS"
     for(var lin = index; lin < arrayTexto.length; lin ++){
        switch(true){
            case arrayTexto[lin].includes("agência e conta:"):
              var pegarConta = arrayTexto[lin+2].split("/")
              agencia = pegarConta[0]
              conta = pegarConta[1]
              lin += 2
              break;
            case arrayTexto[lin].includes("valor recolhido:"):
                valor = arrayTexto[lin+2]
                lin += 2
                break;
            case arrayTexto[lin].includes("transação efetuada em"):
                var pegarData = arrayTexto[lin].split(" ")
                dataPag = pegarData[3].replaceAll(".", "/")
                lin += 2
                break;
            case arrayTexto[lin].includes("CTRL"):
                var pegarDoc = arrayTexto[lin].split(" ")
                doc = pegarDoc[2]
                lin += 2
                break;
      }
      if(agencia != '' && conta != '' &&
      nomeRecebedor != '' && valor != '' &&
      dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const dadosComprovanteOperacaoCodigoBarra = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
     for(var lin = index; lin < arrayTexto.length; lin ++){
        switch(true){
            case arrayTexto[lin].includes("Agência:"):
                agencia = arrayTexto[lin+2]
                lin += 2
                break;
            case arrayTexto[lin].includes("Pagamento com código de barras"):
              nomeRecebedor = arrayTexto[lin+1]
                lin += 1
                break;
            case arrayTexto[lin].includes("Conta:"):
                conta = arrayTexto[lin+2]
                lin += 2
                break;
            case arrayTexto[lin].includes("Valor pago:"):
                valor = arrayTexto[lin+2]
                lin += 2
                break;
            case arrayTexto[lin].includes("Pagamento efetuado em"):
                var pegarData = arrayTexto[lin].split(" ")
                dataPag = pegarData[3].replaceAll(".", "/")
                doc = pegarData[9]
                lin += 2
                break;
      }
      if(agencia != '' && conta != '' &&
      nomeRecebedor != '' && valor != '' &&
      dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const dadosComprovanteOperacaoDARF = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
     nomeRecebedor = "DARF"
     for(var lin = index; lin < arrayTexto.length; lin ++){
        switch(true){
            case arrayTexto[lin].includes("agência e conta:"):
              var pegarConta = arrayTexto[lin+2].split("/")
              agencia = pegarConta[0]
              conta = pegarConta[1]
              lin += 2
              break;
            case arrayTexto[lin].includes("valor total:"):
                valor = arrayTexto[lin+2]
                lin += 2 
                break;
            case arrayTexto[lin].includes("CTRL"):
              var pegarDoc = arrayTexto[lin].split(" ")
              doc = pegarDoc[2]
                lin += 2 
                break;
            case arrayTexto[lin].includes("número do CPF ou CNPJ:"):
                cpfcnpjRecebedor = arrayTexto[lin+2] 
                lin += 2
                break;
            case arrayTexto[lin].includes("CNPJ:"):
                cpfcnpjPagador = arrayTexto[lin+2] 
                lin += 2
                break;
            case arrayTexto[lin].includes("transação efetuada em"):
                var pegarData = arrayTexto[lin].split(" ")
                dataPag = pegarData[3].replaceAll(".", "/")
                lin += 2
                break;
      }
      if(cpfcnpjPagador != '' && agencia != '' && conta != '' &&
      nomeRecebedor != '' && valor != '' &&
      dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const dadosComprovantePagamentoBoleto = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '', 
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
     for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){
        case arrayTexto[lin].includes("Agência/conta:"):
          var pegarConta = arrayTexto[lin+2].split("/")
          agencia = pegarConta[0]
          conta = pegarConta[1]
          lin += 2
          break;
        case arrayTexto[lin].includes("Beneficiário:"):
          nomeRecebedor = arrayTexto[lin+2] 
          lin += 2
          break;
        case arrayTexto[lin].includes("Beneficiário Final:"):
          nomeRecebedor = arrayTexto[lin+7] === " " ?  arrayTexto[lin+6] : arrayTexto[lin+6] + " " + arrayTexto[lin+7]
          lin++
          break;
        case arrayTexto[lin].includes("CPF/CNPJ do pagador:"):
          cpfcnpjPagador = hasNumber(arrayTexto[lin+6]) ? arrayTexto[lin+6] : 
          (hasNumber(arrayTexto[lin+8]) ? arrayTexto[lin+8] : arrayTexto[lin+7])
          lin += 1
          break;
        case arrayTexto[lin].includes("CPF/CNPJ do beneficiário:"):
          cpfcnpjRecebedor = arrayTexto[lin+7] 
          lin += 1 
          break; 
        case arrayTexto[lin].includes("CPF/CNPJ do beneficiário final:"):
          cpfcnpjRecebedor = arrayTexto[lin+6] === " " ? arrayTexto[lin+7] : arrayTexto[lin+6]
          lin += 2
          break;
        case arrayTexto[lin].includes("(=) Valor do pagamento (R$):"): 
          valor = "R$ " + (hasNumber(arrayTexto[lin+4]) ? arrayTexto[lin+6] : 
          (hasNumber(arrayTexto[lin+8]) ? arrayTexto[lin+8] : arrayTexto[lin+7]))
          lin += 2
          break;
        case arrayTexto[lin].includes("Operação efetuada em"):
            var pegarData = arrayTexto[lin].split(" ")
            dataPag = pegarData[3].replaceAll(".", "/")
            doc = pegarData[9].replaceAll(".", "")
            lin += 2
            break;
      }
      if(cpfcnpjPagador != '' && agencia != '' && conta != '' &&
      nomeRecebedor != '' && cpfcnpjRecebedor != '' && valor != '' &&
      dataPag != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const bbComprovantePix = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '', verificaAgencia = 0, verificaConta = 0;
     for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){
        case (arrayTexto[lin].includes("CNPJ") && arrayTexto[lin+2].includes("DO") && arrayTexto[lin+4].includes("PAGADOR")): 
          cpfcnpjPagador = arrayTexto[lin+6] 
          break;
        case (arrayTexto[lin].includes("AGENCIA:") && verificaAgencia === 0): 
          var pegarAgencia = arrayTexto[lin+2].split(" ")
          agencia = pegarAgencia[0]
          verificaAgencia++
          break;
        case (arrayTexto[lin].includes("CONTA:") && verificaConta === 0): 
          conta = arrayTexto[lin+2]
          verificaConta++
          break;
        case (arrayTexto[lin].includes("PAGO") && arrayTexto[lin+2].includes("PARA")): 
          nomeRecebedor = arrayTexto[lin+4] 
          break;
        case (arrayTexto[lin].includes("CNPJ:") || arrayTexto[lin].includes("CPF:")): 
          cpfcnpjRecebedor = arrayTexto[lin+2] 
          break;
        case arrayTexto[lin].includes("VALOR:"): 
          valor = "R$ " + arrayTexto[lin+2]
          break;
        case arrayTexto[lin].includes("DOCUMENTO:"): 
          doc = arrayTexto[lin+2]
          break;
        case arrayTexto[lin].includes("DATA:"):
          var pegarData = arrayTexto[lin+2].split(" ")
          dataPag = pegarData[0].replaceAll(".", "/")
          break;
      }
      if(cpfcnpjPagador != '' && agencia != '' && conta != '' &&
      nomeRecebedor != '' && cpfcnpjRecebedor != '' && valor != '' &&
      dataPag != '' && doc != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const bbSegundaViaPagamentos = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '', multa = '',
     dataPag = '', doc = '';
     for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){ 
        case (arrayTexto[lin].includes("AGENCIA:")): 
          var pegarAgencia = arrayTexto[lin+2].split(" ")
          agencia = pegarAgencia[0]
          break;
        case (arrayTexto[lin].includes("CONTA:")): 
          conta = arrayTexto[lin+2]
          break;
        case (arrayTexto[lin].includes("Convenio")): 
          nomeRecebedor = getNomeRecebedor(arrayTexto, lin + 1)
          break;
        case (arrayTexto[lin].includes("DE PAGAMENTO DE DARF/DARF SIMPLES")): 
          var pegarNome = arrayTexto[lin].split("DE ")  
          nomeRecebedor = pegarNome[pegarNome.length-1]
          break;
        case (arrayTexto[lin].includes("Valor") && arrayTexto[lin+2].includes("Total")): 
          valor = "R$ " + arrayTexto[lin+4]
          break;
        case (arrayTexto[lin].includes("VALOR") && arrayTexto[lin+2].includes("TOTAL")): 
          valor = "R$ " + arrayTexto[lin+4]
          break;
        case arrayTexto[lin].includes("DOCUMENTO:"): 
          doc = arrayTexto[lin+2]
          break;
        case arrayTexto[lin].includes("VALOR ATM/JUROS/MULTA"): 
          multa = "R$ " + arrayTexto[lin+2]
          break;
        case (arrayTexto[lin].includes("Data") && arrayTexto[lin+2].includes("do") && arrayTexto[lin+4].includes("pagamento")):
          dataPag = arrayTexto[lin+6]
          break;
        case (arrayTexto[lin].includes("DATA") && arrayTexto[lin+2].includes("DO") && arrayTexto[lin+4].includes("PAGAMENTO")):
          dataPag = arrayTexto[lin+6]
          break;
      }
      if( agencia != '' && conta != '' &&
      nomeRecebedor != '' && valor != '' &&
      dataPag != '' && doc != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const bbSegundaViaTransferencia = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '', contAgencia = 0, contConta = 0, contCliente = 0;
     for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){ 
        case (arrayTexto[lin].includes("AGENCIA:") && contAgencia === 0): 
          contAgencia++
          var pegarAgencia = arrayTexto[lin+2].split(" ")
          agencia = pegarAgencia[0]
          break;
        case (arrayTexto[lin].includes("CLIENTE:") && contCliente === 0):
          contCliente++;
          break;
        case (arrayTexto[lin].includes("CLIENTE:") && contCliente !== 0):
          nomeRecebedor = getNomeRecebedor(arrayTexto, lin + 1)
          break;
        case (arrayTexto[lin].includes("CONTA:") && contConta === 0): 
          contConta++
          conta = arrayTexto[lin+2]
          break;
        case (arrayTexto[lin].includes("Convenio")): 
          nomeRecebedor = getNomeRecebedor(arrayTexto, lin + 1)
          break;
        case (arrayTexto[lin].includes("DE PAGAMENTO DE DARF/DARF SIMPLES")): 
          var pegarNome = arrayTexto[lin].split("DE ")  
          nomeRecebedor = pegarNome[pegarNome.length-1]
          break;
        case (arrayTexto[lin].includes("Valor") && arrayTexto[lin+2].includes("Total")): 
          valor = "R$ " + arrayTexto[lin+4]
          break;
        case (arrayTexto[lin].includes("VALOR") && arrayTexto[lin+2].includes("TOTAL")): 
          valor = "R$ " + arrayTexto[lin+4]
          break;
        case (arrayTexto[lin].includes("NR.") && arrayTexto[lin+2].includes("DOCUMENTO")): 
          doc = arrayTexto[lin+4]
          break;
        case (arrayTexto[lin].includes("DATA") && arrayTexto[lin+2].includes("DA") && arrayTexto[lin+4].includes("TRANSFERENCIA")):
          dataPag = arrayTexto[lin+6]
          break;
        case (arrayTexto[lin].includes("Data") && arrayTexto[lin+2].includes("do") && arrayTexto[lin+4].includes("pagamento")):
          dataPag = arrayTexto[lin+6]
          break;
        case (arrayTexto[lin].includes("DATA") && arrayTexto[lin+2].includes("DO") && arrayTexto[lin+4].includes("PAGAMENTO")):
          dataPag = arrayTexto[lin+6]
          break;
      }
      if( agencia != '' && conta != '' &&
      nomeRecebedor != '' && valor != '' &&
      dataPag != '' && doc != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const bbSegundaViaPrevidencia = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = "PREVIDENCIA SOCIAL - GPS", cpfcnpjRecebedor = '', valor = '', multa = '',
     dataPag = '', doc = '';
     for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){ 
        case (arrayTexto[lin].includes("AGENCIA:")): 
          var pegarAgencia = arrayTexto[lin+2].split(" ")
          agencia = pegarAgencia[0]
          break;
        case (arrayTexto[lin].includes("CONTA:")): 
          conta = arrayTexto[lin+2]
          break;
        case (arrayTexto[lin].includes("Valor") && arrayTexto[lin+2].includes("Total")): 
          valor = "R$ " + arrayTexto[lin+4]
          break;
        case (arrayTexto[lin].includes("VALOR") && arrayTexto[lin+2].includes("TOTAL")): 
          valor = "R$ " + arrayTexto[lin+4]
          break;
        case arrayTexto[lin].includes("DOCUMENTO:"): 
          doc = arrayTexto[lin+2]
          break;
        case arrayTexto[lin].includes("ATM/JUROS/MULTA"): 
          multa = "R$ " + arrayTexto[lin+2]
          break;
        case (arrayTexto[lin].includes("Data") && arrayTexto[lin+2].includes("do") && arrayTexto[lin+4].includes("pagamento")):
          dataPag = arrayTexto[lin+6]
          break;
        case (arrayTexto[lin].includes("DATA") && arrayTexto[lin+2].includes("DO") && arrayTexto[lin+4].includes("PAGAMENTO")):
          dataPag = arrayTexto[lin+6]
          break;
      }
      if( agencia != '' && conta != '' &&
      nomeRecebedor != '' && valor != '' &&
      dataPag != '' && doc != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador, multa ])
      }
    }
  }

  const bbDebitoAutomatico = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '';
     for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){ 
        case (arrayTexto[lin].includes("AGENCIA:")): 
          var pegarAgencia = arrayTexto[lin+2].split(" ")
          agencia = pegarAgencia[0]
          break;
        case (arrayTexto[lin].includes("CONTA:")): 
          conta = arrayTexto[lin+2]
          break;
        case (arrayTexto[lin].includes("CONVENIO:")): 
          nomeRecebedor = getNomeRecebedor(arrayTexto, lin + 1)
          break;
        case (arrayTexto[lin].includes("VALOR") && arrayTexto[lin+2].includes("DO") && arrayTexto[lin+4].includes("DEBITO")): 
          valor = "R$ " + arrayTexto[lin+6]
          break;
        case arrayTexto[lin].includes("NR.REMESSA:"): 
          doc = arrayTexto[lin+2]
          break;
        case (arrayTexto[lin].includes("DATA") && arrayTexto[lin+2].includes("DO") && arrayTexto[lin+4].includes("DEBITO:")):
          dataPag = arrayTexto[lin+6].replaceAll(".", "/")
          break;
      }
      if( agencia != '' && conta != '' &&
      nomeRecebedor != '' && valor != '' &&
      dataPag != '' && doc != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const bbComprovantePagamentosTitulos = (arrayTexto, index) => {
    console.log(arrayTexto)
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '', multa = '',
     dataPag = '', doc = '', verificaCNPJ = 0, verificaCPF = 0;
     for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){
        case (arrayTexto[lin].includes("CPF:") && verificaCNPJ === 0): 
          var pegaCnpjRecebedor= arrayTexto[lin].split(" ")
          cpfcnpjRecebedor = pegaCnpjRecebedor.length > 1 ? pegaCnpjRecebedor[pegaCnpjRecebedor.length-1] : arrayTexto[lin+2]
          verificaCNPJ++
          verificaCPF++
          break;
        case (arrayTexto[lin].includes("CNPJ:") && verificaCNPJ === 0 && verificaCPF === 0): 
          var pegaCnpjRecebedor= arrayTexto[lin].split(" ")
          cpfcnpjRecebedor = pegaCnpjRecebedor.length > 1 ? pegaCnpjRecebedor[pegaCnpjRecebedor.length-1] : arrayTexto[lin+2]
          verificaCNPJ++
          break;
        case (arrayTexto[lin].includes("AGENCIA:")): 
          var verificaAgencia = arrayTexto[lin].split(" ")
          var pegarAgencia = arrayTexto[lin+2].split(" ")
          verificaAgencia.length > 1 ? agencia = verificaAgencia[verificaAgencia.length-1] : agencia = pegarAgencia[0]
          break;
        case (arrayTexto[lin].includes("CONTA:")): 
          conta = arrayTexto[lin+2]
          break; 
        case (arrayTexto[lin].includes("BENEFICIARIO:")):  
          nomeRecebedor = getNomeRecebedor(arrayTexto, lin + 1) 
          break;
        case (arrayTexto[lin].includes("BENEFICIARIO") && arrayTexto[lin+2].includes("FINAL")):  
          nomeRecebedor = getNomeRecebedor(arrayTexto, lin + 3) 
          verificaCNPJ = 0  
          break;
        case (arrayTexto[lin].includes("BENEFICIARIO FINAL:")):  
          nomeRecebedor = getNomeRecebedor(arrayTexto, lin+1) 
          verificaCNPJ = 0  
          break;
        case ((arrayTexto[lin].includes("CNPJ:") || arrayTexto[lin].includes("CPF:")) && (verificaCNPJ === 1)): 
          var pegaCnpjPagador = arrayTexto[lin].split(" ")
          cpfcnpjPagador = pegaCnpjPagador.length > 1 ? pegaCnpjPagador[pegaCnpjPagador.length-1] : arrayTexto[lin+2] 
          verificaCNPJ++
          break; 
        case (arrayTexto[lin].includes("VALOR") && arrayTexto[lin+2].includes("COBRADO")): 
          valor = "R$ " + arrayTexto[lin+4]
          break;
        case (arrayTexto[lin].includes("VALOR COBRADO")): 
          valor = "R$ " + arrayTexto[lin+2]
          break;
        case (arrayTexto[lin].includes("NR.") && arrayTexto[lin+2].includes("DOCUMENTO")):
          doc = arrayTexto[lin+4]
          break;
        case (arrayTexto[lin].includes("NR. DOCUMENTO")):
          doc = arrayTexto[lin+4]
          break;
        case (arrayTexto[lin].includes("DATA") && arrayTexto[lin+2].includes("DO") && arrayTexto[lin+4].includes("PAGAMENTO")):
          dataPag = arrayTexto[lin+6]
          break;
        case (arrayTexto[lin].includes("DATA DO PAGAMENTO")):
          dataPag = arrayTexto[lin+2]
          break;
        case (arrayTexto[lin].includes("CNPJ:")): 
          var pegaCnpjPagador = arrayTexto[lin].split(" ")
          cpfcnpjPagador = pegaCnpjPagador.length > 1 ? pegaCnpjPagador[pegaCnpjPagador.length-1] : arrayTexto[lin+2] 
          break
        case (arrayTexto[lin].includes("JUROS/MULTA")): 
          multa = "R$ " + arrayTexto[lin+2] 
          break
      }  
      console.log(doc)
      if(cpfcnpjPagador != '' && agencia != '' && conta != '' &&
      nomeRecebedor != '' && cpfcnpjRecebedor != '' && valor != '' &&
      dataPag != '' && doc != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador, multa ])
      }
    }
  }

  const bbComprovanteTed = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '', verificaAgencia = 0, verificaConta = 0;
     for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){
        case (arrayTexto[lin].includes("CPF/CNPJ:")): 
          cpfcnpjRecebedor = arrayTexto[lin+2]  
          break;
        case (arrayTexto[lin].includes("AGENCIA:") && verificaAgencia === 0): 
          var pegarAgencia = arrayTexto[lin+2].split(" ")
          agencia = pegarAgencia[0]
          break;
        case (arrayTexto[lin].includes("CONTA:") && verificaConta === 0): 
          conta = arrayTexto[lin+2]
          break; 
        case (arrayTexto[lin].includes("FAVORECIDO:")):  
          nomeRecebedor = getNomeRecebedor(arrayTexto, lin + 1) 
          break;
        case (arrayTexto[lin].includes("BENEFICIARIO") && arrayTexto[lin+2].includes("FINAL")):  
          nomeRecebedor = getNomeRecebedor(arrayTexto, lin + 3) 
          break;
        case (arrayTexto[lin].includes("VALOR:")): 
          valor = "R$ " + arrayTexto[lin+4]
          break;
        case (arrayTexto[lin+2].includes("DOCUMENTO:")):
          doc = arrayTexto[lin+4]
          break;
        case (arrayTexto[lin].includes("DEBITO") && arrayTexto[lin+2].includes("EM:")):
          dataPag = arrayTexto[lin+4]
          break;
      } 
      if(agencia != '' && conta != '' &&
      nomeRecebedor != '' && cpfcnpjRecebedor != '' && valor != '' &&
      dataPag != '' && doc != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  const hasNumber = (myString) => {
    return /\d/.test(myString); 
  }

  const getNomePagador = (array, ind) => {
    var nomePagador = ''
    while(!array[ind].includes('CNPJ:')){
      nomePagador += array[ind];
      ind++
    }
    return nomePagador;
  }

  const getNomeRecebedor = (array, ind) => {
    var nomePagador = '' 
    while(!array[ind].includes('NOME') && !array[ind+2].includes('FANTASIA:') && 
    !array[ind].includes('CNPJ') && !array[ind].includes('CPF')  && !array[ind].includes("AGENCIA")
    && !array[ind].includes("NR.REMESSA:")
    && !array[ind].includes("Dados do Pagador Original")
    && !(array[ind].includes("Codigo") && array[ind+2].includes("de") && array[ind+4].includes("Barras"))){
      nomePagador += array[ind];
      ind++
    }
    return nomePagador;
  }
 
  const santanderSegundaVia = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '', contCnpj = 0;
     for(var lin = index; lin < arrayTexto.length; lin ++){
      switch(true){
        case (arrayTexto[lin].includes("Dados do Pagador Original")):
          if(arrayTexto[lin+2].includes("Dados do Pagador Efetivo")){
            cpfcnpjPagador = arrayTexto[lin+6]
          } else{ 
            cpfcnpjPagador = arrayTexto[lin+4]
          }
          lin += 2 
          break;
        case arrayTexto[lin].includes("Agência:"):
          agencia = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Conta Corrente:"):
          conta = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Dados do Beneficiário Original"):
          if(arrayTexto[lin+2].includes("Dados do Sacador Avalista")){
            if(arrayTexto[lin+18] === " ")
              nomeRecebedor = arrayTexto[lin+19] 
            else
              nomeRecebedor = arrayTexto[lin+18] 
            cpfcnpjRecebedor = arrayTexto[lin+10] 
          } else {
            nomeRecebedor = arrayTexto[lin+8]  
            cpfcnpjRecebedor = arrayTexto[lin+4]  
          }
          lin += 2
          break
        case arrayTexto[lin].includes("Valor Total a Cobrar:R$"):
          valor = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Data da Transação:"):
          dataPag = arrayTexto[lin+2]
          lin += 2
          break;
        case (arrayTexto[lin].includes("Número de Autenticação da") && arrayTexto[lin+1].includes("Instituição Financeira Favorecida:")):
          doc = arrayTexto[lin+3]
          lin += 2
          break;
      }
      if(cpfcnpjPagador != '' && agencia != '' && conta != '' &&
      nomeRecebedor != '' && cpfcnpjRecebedor != '' && valor != '' &&
      dataPag != '' && doc != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }
 
  const santanderOutraSegundaVia = (arrayTexto, index) => {
    var cpfcnpjPagador = '', agencia = '', conta = '',
     nomeRecebedor = '', cpfcnpjRecebedor = '', valor = '',
     dataPag = '', doc = '', contCnpj = 0;
     for(var lin = 0; lin < arrayTexto.length; lin ++){
      switch(true){
        case (arrayTexto[lin].includes("Dados do Pagador Original")):
          if(arrayTexto[lin+2].includes("Dados do Pagador Efetivo")){
            cpfcnpjPagador = arrayTexto[lin+9]
          } else{ 
            cpfcnpjPagador = arrayTexto[lin+5]
          }
          lin += 2   
          break;
        case (arrayTexto[lin].includes("Agência:")):
          var pegarAgencia = arrayTexto[lin].split(" ")
          agencia = pegarAgencia[1]
          lin += 1
          break;
        case arrayTexto[lin].includes("Conta Corrente:"):
          var pegarConta = arrayTexto[lin].split(": ")
          conta = pegarConta[1] 
          lin += 1
          break;
        case arrayTexto[lin].includes("Dados do Beneficiário Original"):
          if(arrayTexto[lin+2].includes("Dados do Sacador Avalista")){
            if(arrayTexto[lin+18] === " ")
              nomeRecebedor = arrayTexto[lin+13] 
            else
              nomeRecebedor = arrayTexto[lin+12] 
          } else {
            nomeRecebedor = getNomeRecebedor(arrayTexto,lin+6)
          }
          lin += 2
          break
        case arrayTexto[lin].includes("Valor total pago:"):
          valor = arrayTexto[lin+2]
          lin += 2
          break;
        case arrayTexto[lin].includes("Data da Transação:"):
          dataPag = arrayTexto[lin+2]
          lin += 2
          break;
        case (arrayTexto[lin].includes("Número de Autenticação da Instituição Financeira Favorecida:")):
          doc = arrayTexto[lin+2]
          lin += 2
          break;
      }
      if(cpfcnpjPagador != '' && agencia != '' && conta != '' &&
      nomeRecebedor != ''  && valor != '' &&
      dataPag != '' && doc != ''){
        return ([doc, nomeRecebedor, cpfcnpjRecebedor, dataPag,
          valor, agencia, conta, 
          cpfcnpjPagador ])
      }
    }
  }

  export {dadosComprovanteOperacaoConcessionarias,
     dadosComprovanteOperacaoTitulos,
     dadosComprovanteOperacaoTributosEstaduaisMunicipais,
     dadosComprovanteQR,
     dadosComprovanteTransferencia,
     dadosComprovanteTransferenciaCC,
     dadosComprovanteOperacaoGRF,
     dadosComprovanteOperacaoCodigoBarra,
     dadosComprovanteOperacaoDARF,
     dadosComprovanteOperacaoGRRF,
     dadosComprovantePagamentoBoleto,
     itauDadosComprovanteOperacaoConcessionarias,
     itauDadosComprovanteOperacaoTributosEstaduais,
     itauDadosComprovanteOperacaoTributosMunicipais,
     itauDadosComprovanteOperacaoGRF,
     bbComprovantePix,
     bbComprovantePagamentosTitulos,
     bbComprovanteTed,
     bbSegundaViaPagamentos,
     bbDebitoAutomatico,
     bbSegundaViaPrevidencia,
     bbSegundaViaTransferencia,
     santanderSegundaVia,
     santanderOutraSegundaVia}