import DataTable from 'react-data-table-component';

import { useState, useMemo, useRef } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';

import Lottie, {LottieRefCurrentProps} from 'lottie-react';
import animationData from '../files/AnimationLoading.json'

export default function Tabela({ allData, nomeEmpresa, dadosFiltrados, setDadosFiltrados}) {
  
  const animationRef = useRef()
  
const columns = [
  {
    name: 'Documento',
    selector: row => row.dataVenc,
    grow: 1,
    sortable: true,
    reorder: true,
  },
  {
      name: 'Nome do Favorecido',
    selector: row => row.nomeRecebedor,
    grow: 2,
    sortable: true,
    reorder: true,
  },
  {
      name: 'CPF/CNPJ Favorecido',
      selector: row => row.cpfcnpjRecebedor,
      grow: 2,
      right: true,
      sortable: true,
      reorder: true,
  },
  {
    name: 'Data Pagamento',
    selector: row => row.dataPag,
    grow: 1,
    sortable: true,
    reorder: true,
  },
  {
      name: 'Valor',
      selector: row => row.valor,
      grow: 1,
      right: true,
      sortable: true,
      reorder: true,
  },
  {
      name: 'Agência',
      selector: row => row.agencia,
      grow: 1,
      right: true,
      sortable: true,
      reorder: true,
  },
  {
      name: 'Conta',
      selector: row => row.conta,
      grow: 1,
      right: true,
      sortable: true,
      reorder: true,
  },
  {
      name: 'CNPF/CNPJ Pagador',
      selector: row => row.cpfcnpjPagador,
      grow: 2,
      right: true,
      sortable: true,
      reorder: true,
  },
  ];

  const temSeConciliado = (item) => {
    if (selectedSeConciliado.length > 0) {
      for (var ind = 0; ind < selectedSeConciliado.length; ind++){
        if (item.Conciliado === selectedSeConciliado[ind]) {
          return true
        }
      }
      return false
    } else {
      return true
    }
  }


  const temHistorico = (item) => {
    if (item.Historico.includes(selectedHistorico)) {
      return true
    } else {
      return false
    }
  }

  
  const rowsPerPageText = { rowsPerPageText: 'Linhas por Página:' }
  const [selectedSeConciliado, setSelectedSeConciliado] = useState([]);
  const [selectedHistorico, setSelectedHistorico] = useState("");
  const [selectedContas, setSelectedContas] = useState([]);
  const [selectedSimNao, setSelectedSimNao] = useState([]);

  const temContas = (item) => {
    if (selectedContas.length > 0) {
      for (var ind = 0; ind < selectedContas.length; ind++){
        const tempConta = selectedContas[ind].split(" - ")
        if (item.Conta === tempConta[0]) {
          return true
        }
      }
      return false
    } else {
      return true
    }
  }

  function handleFilter () {
    const temp = allData.filter(
      item => (temSeConciliado(item) && temHistorico(item) && temContas(item) && temDesconto(item))
    ) 
    setDadosFiltrados(temp)
  }

  const temDesconto = (item) => {
    switch(selectedSimNao){
      case "Sim":
        if(item.DescontoSugerido > 0.00)
          return true;
        else
          return false;
      case "Não":
        if(item.DescontoSugerido === "0.00")
          return true;
        else
          return false;
      default:
        return true;
    }
  }

  const handleDownload = () => {
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(dadosFiltrados);
    
    XLSX.utils.book_append_sheet(wb, ws, "Conciliação");

    XLSX.utils.sheet_add_aoa(ws, [["Documento", "Nome do Favorecido" , "CPF/CNPJ Favorecido", "Data de Pagamento", "Valor", "Agência", "Conta", "CPF/CNPJ Pagador"]], { origin: "A1" });
    XLSX.writeFile(wb, `Pagamentos.xlsx`);
    
  }

  const actionsMemo = useMemo(() => <button className='downloadButton' onClick={handleDownload}><span>Exportar Excel</span></button>, [dadosFiltrados]);

  return (
    <div className='paginaTabela'>
      <Box sx={{ flexGrow: 1 }}>
        
        <Grid item xs={12}>
          <div className="tabela">
          {!allData  ? 
          <div className='loadingAnimation'>
          <Lottie lottieRef={animationRef} animationData={animationData}/>
          </div>
          : <DataTable
        title={`${nomeEmpresa}`}
          columns={columns}
        data={dadosFiltrados}
        dense
        pagination
        highlightOnHover
        striped
        pointerOnHover
        actions={actionsMemo}
        paginationRowsPerPageOptions={[10, 25, 50, 200, 500]}
          paginationComponentOptions={rowsPerPageText}
      />}
        </div>
          </Grid>
    </Box>
      
      </div>
      );
}