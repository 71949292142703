import React, {useState} from 'react'

import NewspaperIcon from '@mui/icons-material/Newspaper';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';


const ProdutosSection = ( ) => {

  const [active, setActive] = useState(false)

  return (
    <div className='secoesprodutos'>
        <div className='headlineservicos' id="servicos">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f1f8fc" fill-opacity="1" d="M0,160L80,
            181.3C160,203,320,245,480,240C640,235,800,181,960,181.3C1120,181,1280,235,1360,261.3L1440,288L1440,0L1360,0C1280,0,
            1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
            <div className='container'>
                <div className='left'>
                  <div className='tituloservicos'>
                    <h1 className='primeiralinhatitulo'>Você cuida dos negócios.</h1>
                    <h1 className='segundalinhatitulo'>Nós <span>Facilita</span>mos para você.</h1>
                    <div className='linhaseparatitulo'/>
                  </div>
                  <div className='subtitulo'>
                    <h3>
                      {`Disponibilizando soluções em tratamento de importação de arquivos
                      para área contábil, entregando alta performance e inovação para 
                      você focar com tranquilidade no que faz melhor: cuidar dos seus clientes.`}
                    </h3>
                  </div>
                </div>
            </div>
        </div>
        <div className='dadoscards'>
          <div className='container'>
            <div className='card' >
              <div className='content'>
                <h2><NewspaperIcon style={{fontSize: "1em"}}/></h2>
                <h3>Importar NFe</h3>
                <p>{`Com arquivos XML referente as Notas Fiscais eletrônicas (NFe) é possível realizar a importação dos dados
                    através da nossa ferramente, na qual será mostrado cada produto presente nas NFe com suas descriminações de valores e cálculo ICMS já realizado.`}</p>
              </div>
            </div>
            <div className='card' >
              <div className='content'>
                <h2><ReceiptIcon style={{fontSize: "1em"}}/></h2>
                <h3>Importar CFe</h3>
                <p>{`Com arquivos XML referente as Cupons Fiscais eletrônicos (CFe) é possível realizar a importação dos dados
                    através da nossa ferramente, na qual será mostrado cada produto presente nas CFe com suas descriminações de valores e cálculo ICMS já realizado, além do somatório desta informação por CST.`}</p>
              </div>
            </div>
            <div className='card' >
              <div className='content'>
                <h2><RequestQuoteIcon style={{fontSize: "1em"}}/></h2>
                <h3>Razão da Conciliação</h3>
                <p>{`Utilizando arquivos referente a Razão da Conciliação em Excel disponibilizados pelo sistema Fortes é possível realizar a importação
                e identificar de forma rápida e direta dados que já foram conciliados, realizar filtros, além de poder visualizar descontos e juros sugeridos
                em conciliações que há diferença entre os valores de crédito e débito.`}</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ProdutosSection