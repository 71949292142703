import React, { useRef } from 'react'
import { useState } from 'react'
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import ArquivoPDF from '../components/ArquivoPDF';
import Button from '@mui/material/Button';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useReactToPrint } from 'react-to-print';
import ArticleIcon from '@mui/icons-material/Article';

const Curriculo = () => {

const contentDocument = useRef();
const handlePrint = useReactToPrint({
  content: () => contentDocument.current,
})
const handleSavePrint = () => {
  localStorage.setItem("nomeCompleto", nomeCompleto);
  localStorage.setItem("email", email);
  localStorage.setItem("telefone", telefone);
  localStorage.setItem("objetivo", objetivo);
  localStorage.setItem("habilidades", JSON.stringify(habilidades));
  localStorage.setItem("exp", JSON.stringify(exp));
  localStorage.setItem("acad", JSON.stringify(acad));
  handlePrint()
}
const getLocalHabilidades = () => {
  if(localStorage.getItem("habilidades"))
    return JSON.parse(localStorage.getItem("habilidades"))
  else
    return [{habilidade: ''}]
}
const getLocalExp = () => {
  if(localStorage.getItem("exp"))
    return JSON.parse(localStorage.getItem("exp"))
  else
    return [{empresa: '', anoInicio: '', anoFim: '', detalhes: ''}]
}
const getLocalAcad = () => {
  if(localStorage.getItem("acad"))
    return JSON.parse(localStorage.getItem("acad"))
  else
    return [{titulo: '', anoInicio: '', anoFim: '', detalhes: ''}]
}
const getLocalNomeCompleto = () => {
  if(localStorage.getItem("nomeCompleto"))
    return JSON.parse(localStorage.getItem("nomeCompleto"))
  else
    return ''
}
const getLocalTelefone = () => {
  if(localStorage.getItem("telefone"))
    return JSON.parse(localStorage.getItem("telefone"))
  else
    return ''
}
const getLocalEmail = () => {
  if(localStorage.getItem("email"))
    return JSON.parse(localStorage.getItem("email"))
  else
    return ''
}
const getLocalObjetivo = () => {
  if(localStorage.getItem("objetivo"))
    return JSON.parse(localStorage.getItem("objetivo"))
  else
    return ''
}

    const [nomeCompleto, setNomeCompleto] = useState(getLocalNomeCompleto())
    const [telefone, setTelefone] = useState(getLocalTelefone())
    const [email, setEmail] = useState(getLocalEmail())
    const [objetivo, setObjetivo] = useState(getLocalObjetivo())
    const [habilidades, setHabilidades] = useState(getLocalHabilidades())
    const [exp, setExp] = useState(getLocalExp())
    const [acad, setAcad] = useState(getLocalAcad())


    const handleAdd = () => {
      const temp = [...habilidades,{habilidade: ``}]
      if(temp.length > 5){ }
      else {
        setHabilidades(temp)
      }
    }

    const handleAddExp = () => {
      const temp = [...exp,{empresa: '', anoInicio: '', anoFim: '', detalhes: ''}]
      setExp(temp)
    }

    const handleAddAcad = () => {
      const temp = [...acad,{titulo: '', anoInicio: '', anoFim: '', detalhes: ''}]
      setAcad(temp)
    }

    const handleRemove = () => {
      const temp = [...habilidades]
      if(temp.length === 1){
        setHabilidades([{habilidade: ''}])
      } else{
        temp.pop()
        setHabilidades(temp)
      }
    }

    const handleRemoveExp = () => {
      const temp = [...exp]
      if(temp.length === 1){
        setExp([{empresa: '', anoInicio: '', anoFim: '', detalhes: ''}])
      } else{
        temp.pop()
        setExp(temp)
      }
    }

    const handleRemoveAcad = () => {
      const temp = [...acad]
      if(temp.length === 1){
        setAcad([{titulo: '', anoInicio: '', anoFim: '', detalhes: ''}])
      } else{
        temp.pop()
        setAcad(temp)
      }
    }

    const handleHabilidades = (e, index) => {
      const { value} = e.target
      const temp = [...habilidades]
      temp[index]["habilidade"] = value
      setHabilidades(temp)
    }

    const handleExp = (e, index, campo) => {
      const { value} = e.target
      const temp = [...exp]
      temp[index][campo] = value
      setExp(temp)
    }

    const handleAcad = (e, index, campo) => {
      const { value} = e.target
      const temp = [...acad]
      temp[index][campo] = value
      setAcad(temp)
    }

    function Export2Doc(element, filename = ''){
      var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html = preHtml+document.getElementById(element).innerHTML+postHtml;
  
      var blob = new Blob(['\ufeff', html],{
          type: 'application/msword'
      });
  
      var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html)
  
      filename = filename?filename+'.doc': 'document.doc';
  
      var downloadLink = document.createElement("a");
  
      document.body.appendChild(downloadLink);
  
      if(navigator.msSaveOrOpenBlob){
          navigator.msSaveOrOpenBlob(blob, filename);
      }else{
          downloadLink.href = url;
  
          downloadLink.download = filename;
  
          downloadLink.click();
      }
  
      document.body.removeChild(downloadLink);
  
  
  }

  return (
    <div className="AreaDoCurriculo">
        
        <div className="DadosPreenchimento">
            <div style={{width: '100%', textAlign:'center'}}> <h1>Preencha</h1> </div>
            <TextField id="nomeCompleto" size='small' label="Nome Completo" fullWidth value={nomeCompleto}  variant="standard" onChange={(e)=> setNomeCompleto(e.target.value)}
            helperText="Este campo deverá ser preenchido com o Nome Completo da pessoa" />
            <TextField id="telefone" size='small' label="Telefone" fullWidth value={telefone} variant="standard" onChange={(e)=> setTelefone(e.target.value)}
            helperText="Este campo deverá ser preenchido com o Telefone da pessoa" />
            <TextField id="email" size='small' label="E-mail" fullWidth value={email} variant="standard" onChange={(e)=> setEmail(e.target.value)}
            helperText="Este campo deverá ser preenchido com o E-mail da pessoa" />
            <TextField id="resumo" size='small' label="Resumo/Objetivo" fullWidth value={objetivo} variant="standard" onChange={(e)=> setObjetivo(e.target.value)}
            helperText="Este campo deverá ser preenchido com um breve resumo sobre quem você é e o que você procura, além de experiências profissionais relevantes
            que você possa ter tido."
            multiline            rows={4} />
            { habilidades.map((data, index) => (
            <TextField id="habilidades" size='small' 
            label="Habilidades e Competências" fullWidth  
            variant="standard" onChange={(e)=> handleHabilidades(e, index)}
            value = {data.habilidade}
              helperText="Este campo deverá ser preenchido com o habilidades que possam ser úteis para a vaga que está tentando concorrer." 
              />
          ))
              }
              <div className='botoesHabilidade'>
              <Button variant="outlined" color="error" onClick={handleRemove} >
                Remover
              </Button>
              <Button variant="contained" color="success"  onClick={handleAdd} >
                Adicionar
              </Button>
              </div>
            { exp.map((data, index) => (
              <>
              <TextField id="empresa" size='small' 
              label="Empresa" fullWidth  
              variant="standard" onChange={(e)=> handleExp(e, index, "empresa")}
              value = {data.empresa}
                helperText="Este campo deverá ser preenchido com o nome da Empresa que já atuou. (Recomendado preencher da mais recente pra mais antiga)" 
                />
            <TextField id="dataInicio" size='small' 
            label="Data de Início" fullWidth  
            variant="standard" onChange={(e)=> handleExp(e, index, "anoInicio")}
            value = {data.anoInicio}
              helperText="Este campo deverá ser preenchido com o mês e ano que começou a atuar na empresa. 
              Ex: Jan/2024 (Não obrigatório)" 
              />
              <TextField id="dataFim" size='small' 
              label="Data de Término" fullWidth  
              variant="standard" onChange={(e)=> handleExp(e, index, "anoFim")}
              value = {data.anoFim}
                helperText={`Este campo deverá ser preenchido com o mês e ano que saiu da empresa, se não saiu ainda basta digitar "presente".
                Ex: Jan/2024 (Não obrigatório)`}
                />
            <TextField id="detalhes" size='small' 
            label="Detalhes" fullWidth  
            variant="standard" onChange={(e)=> handleExp(e, index, "detalhes")}
            value = {data.detalhes}
            multiline            rows={4} 
              helperText={`Este campo deverá ser preenchido com informações sobre o que e como você atuava nesse emprego, 
              diferentes funções, pessoas que costumava interagir.`}
              />
              </>
          ))
              }
              <div className='botoesHabilidade'>
              <Button variant="outlined" color="error" onClick={handleRemoveExp} >
                Remover
              </Button>
              <Button variant="contained" color="success"  onClick={handleAddExp} >
                Adicionar
              </Button>
              </div>
            { acad.map((data, index) => (
              <>
              <TextField id="titulo" size='small' 
              label="Título/Curso" fullWidth  
              variant="standard" onChange={(e)=> handleAcad(e, index, "titulo")}
              value = {data.titulo}
                helperText="Este campo deverá ser preenchido com o título que recebeu ao se formar, ou o nome do curso que realizou. Ex: Mecânica de Motos, Oficina Primeiros Socorros, Ensino Médio Incompleto... (Recomendado preencher da mais recente pra mais antiga)" 
                />
            <TextField id="dataInicio" size='small' 
            label="Data de Início" fullWidth  
            variant="standard" onChange={(e)=> handleAcad(e, index, "anoInicio")}
            value = {data.anoInicio}
              helperText="Este campo deverá ser preenchido com o mês e ano que começou a cursar. 
              Ex: Jan/2024 (Não obrigatório)" 
              />
              <TextField id="dataFim" size='small' 
              label="Data de Término" fullWidth  
              variant="standard" onChange={(e)=> handleAcad(e, index, "anoFim")}
              value = {data.anoFim}
                helperText={`Este campo deverá ser preenchido com o mês e ano que saiu do curso, se não saiu ainda basta digitar "presente".
                Ex: Jan/2024 (Não obrigatório)`}
                />
            <TextField id="detalhes" size='small' 
            label="Detalhes" fullWidth  
            variant="standard" onChange={(e)=> handleAcad(e, index, "detalhes")}
            value = {data.detalhes}
            multiline            rows={4} 
              helperText={`Este campo deverá ser preenchido com informações sobre o que você aprendeu durante o curso, temp que cursou caso não 
              lembre datas e como atuou no curso em questões práticas.`}
              />
              </>
          ))
              }
              <div className='botoesHabilidade'>
              <Button variant="outlined" color="error" onClick={handleRemoveAcad} >
                Remover
              </Button>
              <Button variant="contained" color="success"  onClick={handleAddAcad} >
                Adicionar
              </Button>
              </div>
              <div className='botoesExportar'>
              <Button variant="contained" color="error" onClick={handleSavePrint}>
                <PictureAsPdfIcon style={{marginRight: '10px'}}/> Exportar para PDF
              </Button>
              </div>
        </div>
        <div className="AreaDoPDF">
        <div ref={contentDocument} className="previewPDF" id="previewPDF" >
            <h1>{nomeCompleto}</h1>
            { nomeCompleto === '' ? ' ' : <div className='linhapreta'></div>}
            <div className='Dados'> 
                {telefone && <div className='dado'>Telefone: {telefone} </div>}
                {email && <div className='dado'>E-mail: {email} </div>}
            </div>
            { telefone === '' && email === '' ? ' ' : <div className='linhacinza'></div>}
            {objetivo && <h3>Resumo</h3>}
            {`${objetivo}`}
            {habilidades[0].habilidade !== '' && <h3>Habilidades e Competências</h3>}
            {
              habilidades.map((habilidade, index) => (
                <ul key={index} className='listaHabilidades'>
                  {habilidade.habilidade && <li> {habilidade.habilidade} </li>}
                </ul>
              ))
            }
            {exp[0].empresa !== '' && <h3>Experiência Profissional</h3>}
            
            {
              exp.map((exp, index) => (
                <>
                  {exp.empresa && 
                  <div key={index}  className='nomeAno'>
                    <div>
                      {exp.empresa}
                    </div>
                      {exp.anoInicio && <div className='margemAno'>
                        {exp.anoInicio + ` - ` + exp.anoFim}
                      </div>}
                  </div>}
                  <div className='detalhes'>
                      {exp.detalhes}
                  </div>
                </>
              ))
            }
            { acad[0].titulo !== "" && <h3>Formação Acadêmica</h3>}
            
            {
              acad.map((acad, index) => (
                <>
                  {acad.titulo && 
                  <div key={index}  className='nomeAno'>
                    <div>
                      {acad.titulo}
                    </div>
                      {acad.anoInicio && <div className='margemAno'>
                        {acad.anoInicio + ` - ` + acad.anoFim}
                      </div>}
                  </div>}
                  <div className='detalhes'>
                      {acad.detalhes}
                  </div>
                </>
              ))
            }
        </div>
    </div>
    </div>
  )
}

export default Curriculo