import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import ImportarXMLNFe from './pages/ImportarXMLNFe'
import ImportarXMLCFe from './pages/ImportarXMLCFe'
import ImportarTXTSped from './pages/ImportarTXTSped'
import AreaDeImportacao from './pages/AreaDeImportacao'
import ImportarExcelFts from './pages/ImportarExcelFts'
import ImportarPagamentos from './pages/ImportarPagamentos'
import Home from './pages/Home'
import Curriculo from './pages/Curriculo'

const App = () => {
  return (
    <Router basename='/'>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/areadocliente" element={<AreaDeImportacao />}>
          <Route path="xmlnfe" element={<ImportarXMLNFe />} />
          <Route path="xmlcfe" element={<ImportarXMLCFe />}/> 
          <Route path="txtsped" element={<ImportarTXTSped />}/>
          <Route path="conciliarfts" element={<ImportarExcelFts />}/> 
          <Route path="pagamentos" element={<ImportarPagamentos />}/> 
        </Route>
        <Route path="/curriculo" element={<Curriculo />}/>
      </Routes>
    </Router>
  )
}

export default App