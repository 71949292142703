import React, {useState} from 'react'

import './Home.css'
import NavBarHome from '../components/NavBarHome'
import InformationHome from '../components/InformationHome'
import ProdutosSection from '../components/ProdutosSection'
import ContactSection from '../components/ContactSection'

const Home = ( ) => {

  const [active, setActive] = useState(false)

  return (
    <div>
        <div className={`big-wrapper ${active? "active": ""}`}>
            <NavBarHome setActive={setActive} active={active} />
            <InformationHome/>
            <div></div>
        </div>
        <ProdutosSection/>
        <ContactSection/>
    </div>
  )
}

export default Home