import { useEffect, useState } from "react";
import InputPDF from "../components/InputPDF";
import { useLocation } from 'react-router-dom'

import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Tabela from "../components/TabelaPagamentos";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { pdfjs} from "react-pdf";

import {dadosComprovanteOperacaoConcessionarias,
  dadosComprovanteOperacaoTitulos,
  dadosComprovanteOperacaoTributosEstaduaisMunicipais,
  dadosComprovanteQR,
  dadosComprovanteTransferencia,
  dadosComprovanteTransferenciaCC,
  dadosComprovanteOperacaoGRF,
  dadosComprovanteOperacaoCodigoBarra,
  dadosComprovanteOperacaoDARF,
  dadosComprovanteOperacaoGRRF,
  dadosComprovantePagamentoBoleto,
  itauDadosComprovanteOperacaoConcessionarias,
  itauDadosComprovanteOperacaoTributosEstaduais,
  itauDadosComprovanteOperacaoTributosMunicipais,
  itauDadosComprovanteOperacaoGRF,
  bbComprovantePix,
  bbComprovantePagamentosTitulos,
  bbComprovanteTed,
  bbSegundaViaPagamentos,
  bbDebitoAutomatico,
  bbSegundaViaPrevidencia,
  bbSegundaViaTransferencia,
  santanderSegundaVia,
  santanderOutraSegundaVia} 
  from '../components/utilsPagamentos'

function ImportarPagamentos({ location }) {

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  location = useLocation();

  const [files, setFiles] = useState();
    const [allData, setAllData] = useState();
  const [cont, setCont] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
  const [nomeEmpresa, setNomeEmpresa] = useState("");
    const [dadosFiltrados, setDadosFiltrados] = useState();
    const linhas = [];

    function createData( dataVenc, nomeRecebedor,  cpfcnpjRecebedor, dataPag, valor, agencia, conta, cpfcnpjPagador) {
      return {dataVenc, nomeRecebedor, cpfcnpjRecebedor, dataPag, valor, agencia, conta, cpfcnpjPagador };
    }


  const getActiveContente = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className="centralizar">
            <div className="UploadBox" >
            <InputPDF title=""
            setFiles={setFiles} 
            />
            <div className="LadoDireito">
              {files !== undefined ?
                <div className={`InfoProgress`}>
                    <div style={{marginBottom: '20px', marginRight: '5px'}}>
                        {!isLoading && <CheckCircleIcon sx={{ color: '#5049CC' }} size={90} />}
                    </div>
                    <div>
                        <div className={`ArquivosProntos  ${!isLoading && "Subir"}`}>
                            { isLoading ? 'Aguarde, os dados estão sendo analisados' : 'Processo finalizado, pode prosseguir'}
                        </div> 
                        {isLoading && <BorderLinearProgress />}
                    
                    </div>
                </div> : ''}

              {files === undefined || isLoading  ? "" : 
                <div className="BotaoContinuar">
                  <button id="continueButton"
                onClick={()=>{setActiveStep(activeStep+1)}}>
                <h3>Continuar</h3> <ArrowForwardIcon size={18}/>
              </button>
              </div>
              }
            </div>
          </div>
          </div>
        );
        case 1:
          return (
            <Tabela nomeEmpresa={nomeEmpresa}
              allData={allData}
              dadosFiltrados={dadosFiltrados}
              setDadosFiltrados={setDadosFiltrados}
            />
          )
        case 2:
          return (
            <div>Testcando</div>
          )
      default:
        return (
          <div>nada pra ver</div>
        )
    }
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 7,
    borderRadius: 5,
    width: 283,
    marginBottom: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#5049CC' : '#5049CC',
    },
  }));

  
   
 
  useEffect(() => {
    if (files !== undefined) {
      var  provPdf, todosPdf;
      readmultifiles(files); 
      function readmultifiles(files) {
        setCont(0);
        var reader = new FileReader(); 
        function readFile(index) {
          if( index >= files.length ) return;
          var file = files[index];
          reader.onload = async (e) => {  
            const pdfURL = reader.result;
            let brabo = await (extractText(pdfURL))
            provPdf = {[index] : brabo}
            
            if (files.length === 1) {
              todosPdf = provPdf;
            } else {
              todosPdf = {...todosPdf, ...provPdf}
            }
            setCont(index+1)
            readFile(index + 1)
            if (index === files.length - 1) {
              dadosPDF( todosPdf);
            }
          }
          reader.readAsDataURL(file)
        }
        readFile(0);
      }
    }
  }, [files])

  async function extractText(pdfUrl) { 
    let pdf;
    pdf = await pdfjs.getDocument(pdfUrl).promise;
    let pages = pdf.numPages;
    let allText = []
    for(let i = 1; i <= pages; i++){
      let page = await pdf.getPage(i);
      let txt = await page.getTextContent();
      let text = txt.items.map((s)=>s.str).join("\n");
      allText.push(text)
    }
    return allText
  }

  const dadosPDF = (dados) => {
    for (var ind in dados) {
      if (dados.hasOwnProperty(ind)) {
        for(var pag = 0; pag < dados[ind].length; pag++){
          var entrou = 0;  
          const arrayTexto = dados[ind][pag].split("\n")
          for(var lin = 0; lin < arrayTexto.length; lin ++){
            switch(true){
              case (arrayTexto[lin].includes("Comprovante de Operação") && 
              (arrayTexto[lin+4].includes("Títulos Outros Bancos") || arrayTexto[lin+4].includes("Títulos Itaú"))):
                entrou++;
                var result = dadosComprovanteOperacaoTitulos(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("Títulos >") && 
              (arrayTexto[lin+2].includes("2ª via de Comprovante")) ):
                entrou++;
                var result = santanderSegundaVia(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("Títulos > 2ª via de Comprovante")):
                entrou++;
                var result = santanderOutraSegundaVia(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("Comprovante Pix")):
                entrou++;
                var result = bbComprovantePix(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("SEGUNDA VIA") 
              && arrayTexto[lin+3].includes("COMPROVANTE DE PAGAMENTO")
              && !arrayTexto[lin+14].includes("PREVIDENCIA SOCIAL - GPS")
              ):
                entrou++;
                var result = bbSegundaViaPagamentos(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("SEGUNDA VIA") 
              && arrayTexto[lin+3].includes("COMPROVANTE DE PAGAMENTO")
              && arrayTexto[lin+14].includes("PREVIDENCIA SOCIAL - GPS")
              && entrou === 0):
                entrou++;
                var result = bbSegundaViaPrevidencia(arrayTexto,lin);
                result ? ( (result[8] === '' || result[8] === 'R$ 0,00') ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : (linhas.push(createData(
                  'M-' +result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) && linhas.push(createData(
                  'M-' +result[0], result[1],
                  result[2], result[3], 
                  result[8], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("SEGUNDA VIA") 
              && arrayTexto[lin+3].includes("COMPROVANTE DE TRANSFERENCIA")
              && !arrayTexto[lin+5].includes("TED")):
                entrou++;
                var result = bbSegundaViaTransferencia(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("COMPROVANTE DE PAGAMENTO DE TITULOS")):
                entrou++;
                var result = bbComprovantePagamentosTitulos(arrayTexto,lin);
                result ? ( (result[8] === '' || result[8] === '0,00') ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : (linhas.push(createData(
                  'M-' +result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) && linhas.push(createData(
                  'M-' +result[0], result[1],
                  result[2], result[3], 
                  result[8], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("COMPROVANTE DE DEBITO AUTOMATICO")):
                entrou++;
                var result = bbDebitoAutomatico(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("Comprovante de Operação") && 
              arrayTexto[lin+4].includes("Concessionárias")):
              entrou++;
              result = dadosComprovanteOperacaoConcessionarias(arrayTexto,lin);
              result ? linhas.push(createData(
                result[0], result[1],
                result[2], result[3], 
                result[4], result[5],
                result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
              )) : linhas.push(createData(
                " ", " ",
                " ", "Página não lida " + (pag + 1), 
                " ", " ",
                " "
              ))
                break
              case (arrayTexto[lin].includes("Banco Itaú - Comprovante de Pagamento de concessionárias")):
              entrou++;
              result = itauDadosComprovanteOperacaoConcessionarias(arrayTexto,lin);
              result ? linhas.push(createData(
                result[0], result[1],
                result[2], result[3], 
                result[4], result[5],
                result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
              )) : linhas.push(createData(
                " ", " ",
                " ", "Página não lida " + (pag + 1), 
                " ", " ",
                " "
              ))
                break
              case (arrayTexto[lin].includes("TED") 
              && arrayTexto[lin+4].includes("TRANSFERENCIA ELETRONICA DISPONIVEL")):
              entrou++;
              result = bbComprovanteTed(arrayTexto,lin);
              result ? linhas.push(createData(
                result[0], result[1],
                result[2], result[3], 
                result[4], result[5],
                result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
              )) : linhas.push(createData(
                " ", " ",
                " ", "Página não lida " + (pag + 1), 
                " ", " ",
                " "
              ))
                break
              case (arrayTexto[lin].includes("Comprovante de Operação") && 
              (arrayTexto[lin+4].includes("Tributos Estaduais com código de barras") || 
              arrayTexto[lin+4].includes("Tributos Municipais"))):
              entrou++;
                result = dadosComprovanteOperacaoTributosEstaduaisMunicipais(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("Banco Itaú - Comprovante de Pagamento") && 
              arrayTexto[lin+1].includes("Tributos Estaduais com código de barras") ):
              entrou++;
                result = itauDadosComprovanteOperacaoTributosEstaduais(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("Banco Itaú - Comprovante de Pagamento") && 
              arrayTexto[lin+1].includes("GRF - Guia de Recolhimento do FGTS") ):
              entrou++;
                result = itauDadosComprovanteOperacaoGRF(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("Banco Itaú - Comprovante de Pagamento") && 
              arrayTexto[lin+1].includes("Tributos Municipais") ):
              entrou++;
                result = itauDadosComprovanteOperacaoTributosMunicipais(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
                case (arrayTexto[lin].includes("Comprovante de pagamento QR Code")):
                  entrou++;
                  result = dadosComprovanteQR(arrayTexto,lin);
                  result ? linhas.push(createData(
                    result[0], result[1],
                    result[2], result[3], 
                    result[4], result[5],
                    result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                  )) : linhas.push(createData(
                    " ", " ",
                    " ", "Página não lida " + (pag + 1), 
                    " ", " ",
                    " "
                  ))
                  break
              case (arrayTexto[lin] === "Comprovante de Transferência"):
                entrou++;
                result = dadosComprovanteTransferencia(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("Comprovante de Operação") && 
              (arrayTexto[lin+4].includes("Transferência de Conta Corrente para Conta Corrente"))):
              entrou++;
                result = dadosComprovanteTransferenciaCC(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("de conta corrente para conta corrente")):
              entrou++; 
                result = dadosComprovanteTransferenciaCC(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break
              case (arrayTexto[lin].includes("Comprovante de Operação") && 
              (arrayTexto[lin+2].includes("GRF - Guia de Recolhimento do FGTS"))):
              entrou++;
                result = dadosComprovanteOperacaoGRF(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break 
              case (arrayTexto[lin].includes("Comprovante de Pagamento - GRRF - Guia de Recolhimento Rescisório FGTS") ):
              entrou++;
                result = dadosComprovanteOperacaoGRRF(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break 
              case (arrayTexto[lin].includes("Comprovante de Operação") && 
              (arrayTexto[lin+4].includes("Pagamento com código de barras"))):
              entrou++;
                result = dadosComprovanteOperacaoCodigoBarra(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break 
              case (arrayTexto[lin].includes("Comprovante de pagamento - DARF")):
              entrou++;
                result = dadosComprovanteOperacaoDARF(arrayTexto,lin);
                result ? linhas.push(createData(
                  result[0], result[1],
                  result[2], result[3], 
                  result[4], result[5],
                  result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                )) : linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                ))
                break 
                case (arrayTexto[lin].includes("Comprovante de pagamento de boleto")):
                  entrou++
                  result = dadosComprovantePagamentoBoleto(arrayTexto,lin);
                  result ? linhas.push(createData(
                    result[0], result[1],
                    result[2], result[3],  
                    result[4], result[5], 
                    result[6], (result[7] !== '' && result[7].length < 15)? 'CPF' : result[7]
                  )) :  linhas.push(createData(
                    " ", " ",
                    " ", "Página não lida " + (pag + 1), 
                    " ", " ",
                    " "
                  ))
                  break 
            } 
          } 
          if(entrou === 0 && arrayTexto.length > 10){
            //cpfcnpjPagador, agencia, conta, nomeRecebedor, cpfcnpjRecebedor, valor, data
            linhas.push(createData(
                  " ", " ",
                  " ", "Página não lida " + (pag + 1), 
                  " ", " ",
                  " "
                )) 
          } 
        }
      }
    }
    setAllData([...linhas]);
    setDadosFiltrados([...linhas])
  }

  return (
    <div className="App">
      {getActiveContente()}
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {activeStep === 0 ? '' :
            <div className="BotaoVoltar">
              <button id="backButton"
                onClick={() => { setActiveStep(activeStep - 1) }}>
                <h4>Voltar</h4>
              </button>
            </div>}
        </div>
    </div>
  );
}

export default ImportarPagamentos;
