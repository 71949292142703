import DataTable from 'react-data-table-component';

import SelectedSeConciliado from './SelectSeConciliado'
import SelectConta from './SelectConta'
import SelectSimNao from './SelectSimNao'
import { useState, useMemo } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function Tabela({ allData, nomeEmpresa, dadosFiltrados, setDadosFiltrados}) {

  
const columns = [
  {
    name: 'Data',
    selector: row => row.Data,
    width: '110px',
    sortable: true,
    reorder: true,
  },
  {
      name: 'Est.',
    selector: row => row.Est,
    width: '70px',
    sortable: true,
    reorder: true,
  },
  {
      name: 'Conta',
      selector: row => row.Conta,
      width: '110px',
      sortable: true,
      reorder: true,
  },
  {
      name: 'Fornecedor',
      selector: row => row.Fornecedor,
      width: '250px',
      sortable: true,
      reorder: true,
  },
  {
      name: 'Histórico',
      selector: row => row.Historico,
      width: '250px',
      sortable: true,
      reorder: true,
  },
  {
      name: 'Contrapart.',
      selector: row => row.Contrapart,
      grow: 2,
      sortable: true,
      reorder: true,
  },
  {
      name: 'Est-cp',
      selector: row => row.Estcp,
      grow: 1,
      sortable: true,
      reorder: true,
  },
  {
      name: 'Chave',
    selector: row => row.Chave,
    grow: 2,
    sortable: true,
    right: true,
    reorder: true,
  },
  {
      name: 'Desconto Sugerido',
    selector: row => row.DescontoSugerido,
    grow: 2,
    sortable: true,
    right: true,
    reorder: true,
  },
  {
      name: 'Débito',
      selector: row => row.Debito,
    grow: 1,
    right: true,
      sortable: true,
      reorder: true,
  },
  {
      name: 'Crédito',
    selector: row => row.Credito,
    grow: 1,
    sortable: true,
    right: true,
    reorder: true,
  },
  {
      name: 'Saldo',
      selector: row => row.Saldo,
      grow: 1,
      sortable: true,
      right: true,
      reorder: true,
  },
  {
      name: 'Conciliado',
    selector: row => row.Conciliado,
    width: '110px',
    right: true,
    sortable: true,
    reorder: true,
  },
  ];

  const temSeConciliado = (item) => {
    if (selectedSeConciliado.length > 0) {
      for (var ind = 0; ind < selectedSeConciliado.length; ind++){
        if (item.Conciliado === selectedSeConciliado[ind]) {
          return true
        }
      }
      return false
    } else {
      return true
    }
  }


  const temHistorico = (item) => {
    if (item.Historico.includes(selectedHistorico)) {
      return true
    } else {
      return false
    }
  }

  
  const rowsPerPageText = { rowsPerPageText: 'Linhas por Página:' }
  const [selectedSeConciliado, setSelectedSeConciliado] = useState([]);
  const [selectedHistorico, setSelectedHistorico] = useState("");
  const [selectedContas, setSelectedContas] = useState([]);
  const [selectedSimNao, setSelectedSimNao] = useState([]);

  const temContas = (item) => {
    if (selectedContas.length > 0) {
      for (var ind = 0; ind < selectedContas.length; ind++){
        const tempConta = selectedContas[ind].split(" - ")
        if (item.Conta === tempConta[0]) {
          return true
        }
      }
      return false
    } else {
      return true
    }
  }

  function handleFilter () {
    const temp = allData.filter(
      item => (temSeConciliado(item) && temHistorico(item) && temContas(item) && temDesconto(item))
    ) 
    setDadosFiltrados(temp)
  }

  const temDesconto = (item) => {
    switch(selectedSimNao){
      case "Sim":
        if(item.DescontoSugerido > 0.00)
          return true;
        else
          return false;
      case "Não":
        if(item.DescontoSugerido === "0.00")
          return true;
        else
          return false;
      default:
        return true;
    }
  }

const prepararDownload = (dados) => {
  for(var ind = 2; ind <= dadosFiltrados.length + 1; ind ++){
    const valorDescontoNumber = dados["I" + ind].v === " " ? " " : parseFloat(dados["I" + ind].v)
    XLSX.utils.sheet_add_aoa(dados, [[valorDescontoNumber]], { origin: "I" + ind });
    dados["I" + ind].z = '#,##0.00_);\\(#,##0.00\\)'
    const valorCreditoNumber = dados["K" + ind].v === " " ? " " : parseFloat(dados["K" + ind].v)
    XLSX.utils.sheet_add_aoa(dados, [[valorCreditoNumber]], { origin: "K" + ind });
    dados["K" + ind].z = '#,##0.00_);\\(#,##0.00\\)'
    const valorDebitoNumber = dados["J" + ind].v === " " ? " " : parseFloat(dados["J" + ind].v)
    XLSX.utils.sheet_add_aoa(dados, [[valorDebitoNumber]], { origin: "J" + ind });
    dados["J" + ind].z = '#,##0.00_);\\(#,##0.00\\)'
  }
}

  const handleDownload = () => {
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(dadosFiltrados);
    
    XLSX.utils.book_append_sheet(wb, ws, "Conciliação");

    XLSX.utils.sheet_add_aoa(ws, [["Est.", , ,"Histórico", "Contrapart.", "Est-cp", , "Desconto Sugerido","Débito", "Crédito"]], { origin: "B1" });
    prepararDownload(ws)
    XLSX.writeFile(wb, `${nomeEmpresa}.xlsx`);
    
  }

  const actionsMemo = useMemo(() => <button className='downloadButton' onClick={handleDownload}><span>Exportar Excel</span></button>, [dadosFiltrados]);

  return (
    <div className='paginaTabela'>
      <Box sx={{ flexGrow: 1 }}>
      <Grid container>
          <Grid item xs={12}>
            <div className='filtros'>
              <Grid >
                <Grid item xs={12}>
                  <div className="titulo">
                    Filtros da Tabela
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                    <TextField id="Historico" label="Histórico" onChange={(e)=> setSelectedHistorico(e.target.value)}  fullWidth  variant="outlined" />
                </Grid>
                <Grid item xs={2}>
                    <SelectedSeConciliado allData={allData} setSelectedSeConciliado={ setSelectedSeConciliado} />
                </Grid>
                <Grid item xs={2}>
                    <SelectSimNao allData={allData} setSelectedSimNao={ setSelectedSimNao} />
                </Grid>
                <Grid item xs={6}>
                    <SelectConta allData={allData} setSelectedContas={ setSelectedContas} />
                </Grid>
              </Grid>
              
              <Grid item xs={12}>
                <div className="dadoscentrais">
                  <button className='downloadButton' onClick={handleFilter}>Atualizar  Tabela</button>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="tabela">
          <DataTable
        title={`${nomeEmpresa}`}
          columns={columns}
        data={dadosFiltrados}
        dense
        pagination
        highlightOnHover
        striped
        pointerOnHover
        actions={actionsMemo}
        paginationRowsPerPageOptions={[10, 25, 50, 200, 500]}
          paginationComponentOptions={rowsPerPageText}
      />
        </div>
          </Grid>
    </Box>
      
      </div>
      );
}