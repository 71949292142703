import DataTable from 'react-data-table-component';

import SelectCST from './SelectCST';
import SelectCFOP from './SelectCFOP';
import SelectNCM from './SelectNCM';
import { useState, useMemo } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';


export default function Tabela({ allData, nomeEmpresa, dadosFiltrados, setDadosFiltrados, pdfNotas, setRetAtivo, retAtivo}) {


  const columns = [
  {
      name: 'Chave',
    selector: row => row.Chave,
    width: '370px',
    sortable: true,
    reorder: true,
  },
  {
      name: 'Nota Fiscal',
    selector: row => row.Nota_Fiscal,
    width: '110px',
    sortable: true,
    reorder: true,
  },
  {
      name: 'NCM',
    selector: row => row.NCM,
    grow: 1,
    sortable: true,
    reorder: true,
  },
  {
      name: 'Data Emissão',
    selector: row => row.Data,
    width: '120px',
    sortable: true,
    reorder: true,
  },
  {
      name: 'Empresa',
      selector: row => row.Empresa,
      width: '300px',
      sortable: true,
      reorder: true,
  },
  {
      name: 'Produto',
      selector: row => row.Produto,
      width: '350px',
      sortable: true,
      reorder: true,
  },
  {
      name: 'CST',
      selector: row => row.CST,
      width: '80px',
      sortable: true,
      reorder: true,
  },
  {
      name: 'CFOP',
      selector: row => row.CFOP,
      width: '100px',
      sortable: true,
      reorder: true,
  },
  {
      name: 'IPI',
      selector: row => row.IPI,
      width: '60px',
      reorder: true,
  },
  {
      name: 'Valor',
    selector: row => row.Valor_Produto,
    width: '100px',
    right: true,
    reorder: true,
  },
  {
      name: 'Desconto',
    selector: row => row.Desconto,
    width: '100px',
    right: true,
    sortable: true,
    reorder: true,
  },
  {
      name: 'Outro',
    selector: row => row.Outras_Despesas,
    width: '100px',
    right: true,
    sortable: true,
    reorder: true,
  },
  {
      name: 'Frete',
    selector: row => row.Frete,
    width: '80px',
    right: true,
    sortable: true,
    reorder: true,
  },
  {
      name: 'ICMS',
    selector: row => row.ICMS,
    width: '80px',
    right: true,
    reorder: true,
    omit: retAtivo
  },
  {
      name: 'ICMS RET',
    selector: row => row.ICMSRET,
    width: '100px',
    right: true,
    reorder: true,
    omit: !retAtivo
  },
  {
      name: 'DANFE',
    selector: row => danfePdf(row.Chave),
    width: '80px',
    center: true,
    reorder: true,
  },
  ];
  
  const danfePdf = (chave) => {
    return <div style={{cursor: "pointer"}} onClick={()=> imprimirPdf(chave)}><PictureAsPdfIcon /></div>
  }

  const imprimirPdf = async (chave) => {
    let corpo = pdfNotas[chave]
    let base = await fetch('https://ws.meudanfe.com/api/v1/get/nfe/xmltodanfepdf/API', {
         method: "POST",
         body: corpo
    });
    if (200 !== base.status) {
      alert("Falha ao gerar PDF! Por favor, tente mais tarde.")
    }
    let pdf = await base.json();
    try {
      let novaAba = window.open("about:blank");
      novaAba.document.write("<html<head><title>" + chave + ".pdf</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>")
      novaAba.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64," + pdf + "'></embed></body></html>")
  } catch (l) {
      alert("Para a exibiçãoo do PDF, favor permitir janela pop-up no navegador. Após permitir favor tentar novamente.")
  }
  }

  const temCST = (item) => {
    if (selectedCST.length > 0) {
      for (var ind = 0; ind < selectedCST.length; ind++){
        if (item.CST === selectedCST[ind]) {
          return true
        }
      }
      return false
    } else {
      return true
    }
  }

  const temCFOP = (item) => {
    if (selectedCFOP.length > 0) {
      for (var ind = 0; ind < selectedCFOP.length; ind++){
        if (item.CFOP === selectedCFOP[ind]) {
          return true
        }
      }
      return false
    } else {
      return true
    }
  }

  const temNCM = (item) => {
    if (selectedNCM.length > 0) {
      for (var ind = 0; ind < selectedNCM.length; ind++){
        if (item.NCM === selectedNCM[ind]) {
          return true
        }
      }
      return false
    } else {
      return true
    }
  }

  const temNota = (item) => {
    if (item.Nota_Fiscal.includes(selectedNota)) {
      return true
    } else {
      return false
    }
  }

  const somarICMS = (item) => {
    var temp = 0;
    item.map((nota) => temp = temp + parseFloat(nota.ICMS))
    return temp
  }
  const somarICMSRET = (item) => {
    var temp = 0;
    item.map((nota) => temp = temp + parseFloat(nota.ICMSRET))
    return temp
  }
  const rowsPerPageText = { rowsPerPageText: 'Linhas por Página:' }
  const [selectedCST, setSelectedCST] = useState([]);
  const [selectedCFOP, setSelectedCFOP] = useState([]);
  const [selectedNCM, setSelectedNCM] = useState([]);
  const [selectedNota, setSelectedNota] = useState("");
  const [sumICMS, setSumICMS] = useState(somarICMS(allData));
  const [sumICMSRET, setSumICMSRET] = useState(somarICMSRET(allData));

  function handleFilter () {
    const temp = allData.filter(
      item => (temCFOP(item) && temCST(item) && temNCM(item) && temNota(item))
    ) 
    setDadosFiltrados(temp)
    setSumICMS(somarICMS(temp))
    setSumICMSRET(somarICMSRET(temp))
  }

  const prepararDownload = (dados) => {
  if(retAtivo){
    XLSX.utils.sheet_add_aoa(dados, [["Chave", "Nota Fiscal" , "NCM", "Data",
     "Empresa", "Produto", "CST", "CFOP", "IPI", "Valor do Produto",
      "Desconto", "Outras Despesas", "Frete", "ICMS RET", ""]], { origin: "A1" });
  } else{
    XLSX.utils.sheet_add_aoa(dados, [["Chave", "Nota Fiscal" , "NCM", "Data",
     "Empresa", "Produto", "CST", "CFOP", "IPI", "Valor do Produto",
      "Desconto", "Outras Despesas", "Frete", "ICMS", ""]], { origin: "A1" });
  }

    for(var ind = 2; ind <= dadosFiltrados.length + 1; ind ++){
      const valorIPI = dados["I" + ind].v === " " ? " " : parseFloat(dados["I" + ind].v)
      XLSX.utils.sheet_add_aoa(dados, [[valorIPI]], { origin: "I" + ind });
      dados["I" + ind].z = '#,##0.00_);\\(#,##0.00\\)'
      
      const valorProduto = dados["J" + ind].v === " " ? " " : parseFloat(dados["J" + ind].v)
      XLSX.utils.sheet_add_aoa(dados, [[valorProduto]], { origin: "J" + ind });
      dados["J" + ind].z = '#,##0.00_);\\(#,##0.00\\)'

      const valorDesconto = dados["K" + ind].v === " " ? " " : parseFloat(dados["K" + ind].v)
      XLSX.utils.sheet_add_aoa(dados, [[valorDesconto]], { origin: "K" + ind });
      dados["K" + ind].z = '#,##0.00_);\\(#,##0.00\\)'

      const valorOutrasDespesas = dados["L" + ind].v === " " ? " " : parseFloat(dados["L" + ind].v)
      XLSX.utils.sheet_add_aoa(dados, [[valorOutrasDespesas]], { origin: "L" + ind });
      dados["L" + ind].z = '#,##0.00_);\\(#,##0.00\\)'

      const valorFrete = dados["M" + ind].v === " " ? " " : parseFloat(dados["M" + ind].v)
      XLSX.utils.sheet_add_aoa(dados, [[valorFrete]], { origin: "M" + ind });
      dados["M" + ind].z = '#,##0.00_);\\(#,##0.00\\)'

      if(retAtivo){
        const valorICMSRET = dados["O" + ind].v === " " ? " " : parseFloat(dados["O" + ind].v)
        XLSX.utils.sheet_add_aoa(dados, [[valorICMSRET]], { origin: "N" + ind });
        dados["N" + ind].z = '#,##0.00_);\\(#,##0.00\\)'
        XLSX.utils.sheet_add_aoa(dados, [[""]], { origin: "O" + ind });
      } else{
        const valorICMS = dados["N" + ind].v === " " ? " " : parseFloat(dados["N" + ind].v)
        XLSX.utils.sheet_add_aoa(dados, [[valorICMS]], { origin: "N" + ind });
        dados["N" + ind].z = '#,##0.00_);\\(#,##0.00\\)'
        XLSX.utils.sheet_add_aoa(dados, [[""]], { origin: "O" + ind });
      }
    }
  }

  const handleDownload = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(dadosFiltrados);
    
      ws['!autofilter']={ref:"A1:N1"};
    XLSX.utils.book_append_sheet(wb, ws, "ICMS");


    prepararDownload(ws)
    XLSX.writeFile(wb, `ICMS - ${nomeEmpresa}.xlsx`);
    
  }

  const toggle = (event) =>{
    setRetAtivo(!retAtivo);
  }

  const IOSSwitch = styled((props) => (
  <Switch onChange={toggle} checked={retAtivo} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props}/>
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#5049CC' : '#5049CC',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

  const actionsMemo = useMemo(() => <button className='downloadButton' onClick={handleDownload}><span>Exportar Excel</span></button>, [dadosFiltrados, retAtivo]);
  
  return (
    <div className='paginaTabela'>
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
          <Grid item xs={9}>
            <div className='filtros'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="titulo">
                    Filtros da Tabela
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectNCM allData={allData} setSelectedNCM={setSelectedNCM} />
                  </Grid>
                  <Grid item xs={6}>
                  <div style={{marginTop: '10px', marginBottom: '10px'}}><TextField id="notaFiscal" label="Nota Fiscal" onChange={(e)=> setSelectedNota(e.target.value)}  fullWidth  variant="outlined" /></div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SelectCST allData={allData} setSelectedCST={setSelectedCST} />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectCFOP allData={allData} setSelectedCFOP={ setSelectedCFOP} />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                <div className="alinharEsquerda" >
                  <IOSSwitch sx={{ m: 1 }} id="switch"  />
                  <label for="switch">ICMS RET</label>
                </div>
                  </Grid>
                  <Grid item xs={6}>
                  <div className="alinharDireita">
                  <button className='downloadButton' onClick={handleFilter}>Atualizar Tabela</button>
                </div>
                </Grid>
              </Grid>
            </div>
        </Grid>
          <Grid item xs={3}>
            <div className="somaAliquota">
              <div className="dadosSomatorio">
              <h2>Somatório ICMS</h2>
              <h2>
                {retAtivo ?
                Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(sumICMSRET.toFixed(2)) : 
                Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(sumICMS.toFixed(2)) }
                </h2>

              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="tabela">
          <DataTable
        title={`Cálculo ICMS - ${nomeEmpresa}`}
          columns={columns}
        data={dadosFiltrados}
        dense
        pagination
        highlightOnHover
        striped
        actions={actionsMemo}
        paginationRowsPerPageOptions={[10, 25, 50, 200, 500]}
          paginationComponentOptions={rowsPerPageText}
      />
        </div>
          </Grid>
    </Box>
      
      </div>
      );
}