import React, {useRef, useState} from 'react'
import { Link } from 'react-router-dom';

import Lottie, {LottieRefCurrentProps} from 'lottie-react';
import animationData from '../files/AnimationOne.json'


const InformationHome = ( ) => {

  const animationRef = useRef()

  return (
    <div className='container'>
      <div className='showcase-area'>
        <div className='left'>
          <div className='big-title'>
            <h1>
              o<span>Facilitador</span>.pro
            </h1>
          </div>
            <p className='text'>
              O seu dia a dia muito mais fácil!
            </p>
            <div className='cta'>
            <a href='#servicos'>
              <span className='btn'>Saiba mais</span>
            </a>
            </div>
        </div>
        <div className='right'>
          <div className='animation'>
            <Lottie lottieRef={animationRef} animationData={animationData}/>
          </div>
        </div>
    </div>
    </div>
  )
}

export default InformationHome