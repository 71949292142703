import { useEffect, useState } from "react";
import InputExcel from "../components/InputExcel";
import { useLocation } from 'react-router-dom'

import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Tabela from "../components/TabelaConciliacao";
import * as XLSX from "xlsx"

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


function ImportarExcelFts({ location }) {
  location = useLocation();

  const [files, setFiles] = useState();
    const [allData, setAllData] = useState();
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const linhasDados = [];
    var colunasDados;
    const [nomeEmpresa, setNomeEmpresa] = useState("");
    const [dadosFiltrados, setDadosFiltrados] = useState();
    
  function createData(Data, Est , Conta, Fornecedor, Historico, Contrapart, Estcp, Chave, DescontoSugerido, Debito, Credito, Saldo, Conciliado
  ) {
    return {
      Data, Est , Conta, Fornecedor, Historico, Contrapart, Estcp,Chave, DescontoSugerido, Debito, Credito, Saldo, Conciliado
    };
  }


    const dadosExcel = (dados) => {
      const colunaData = saberColuna("Data");
      const colunaEst = saberColuna("Est.");
      const colunaHistorico = saberColuna("Histórico");
      const colunaContrapart = saberColuna("Contrapart.");
      const colunaEstcp = saberColuna("Est-cp");
      const colunaChave = saberColuna("Chave");
      const colunaDebito = saberColuna("Débito"); 
      const colunaCredito = saberColuna("Crédito");
      const colunaSaldo = saberColuna("Saldo");
      var conta = "Única"
      var descontoSugerido = 0.00
        for (var ind = 0; ind < dados.length; ind ++) {
          if (dados.hasOwnProperty(ind)) {
            if(dados[ind].length === 1 && dados[ind][0].includes("Conta: ")){
              var temp = dados[ind][0].split("Conta: ")
              conta = temp[1].split(" - ")
            }
            var temData = 0;
            if(typeof(dados[ind][colunaData]) === typeof("string")){
              temData = dados[ind][colunaData].length
            }
            if (typeof(dados[ind][colunaData]) === 'number' || temData === 10) {
              const data = temData === 10 ? dados[ind][colunaData] : ExcelDateToJSDate(dados[ind][colunaData]);
              var historico = dados[ind][colunaHistorico]
              if(dados.hasOwnProperty(ind + 1) && dados[ind+1].length === 4 && hasNumber(dados[ind+1])){
                historico = historico + dados[ind+1][colunaHistorico]
              }
              const valorCredito = dadoNumerico (dados[ind][colunaCredito])
              const valorDebito = dadoNumerico (dados[ind][colunaDebito]) 
              linhasDados.push(createData( 
                data, 
                colunaEst === 0 ? " " : dados[ind][colunaEst],
                conta[0],
                conta[1],
                colunaHistorico === 0 ? " " : historico,
                colunaContrapart === 0 ? " " : dados[ind][colunaContrapart],
                colunaEstcp === 0 ? " " : dados[ind][colunaEstcp],
                colunaChave === 0 ? " " : dados[ind][colunaChave],
                descontoSugerido.toFixed(2),
                valorDebito,
                valorCredito,
                colunaSaldo === 0 ? " " : dados[ind][colunaSaldo],
                "Não"
                ))
            }
          }
        }
        realizarConciliacao(linhasDados)
        setAllData([...linhasDados]);
        setDadosFiltrados([...linhasDados])
        setIsLoading(false)
  }  

  const dadoNumerico = (info) => {
    if(info === "" || info === " "){
      return " "
    } else {
      if(typeof(info) === typeof("string")){
        return (parseFloat(info.replace(".","").replace(',', '.')).toFixed(2))
      } else {
        return (parseFloat(info).toFixed(2))
      }
    }
  }


  const realizarConciliacao = (dados) => {
    for (var ind = 0; ind < dados.length; ind ++) { 
      if(dados[ind].Conciliado === "Não"){ 
        for (var nex = ind + 1; nex < dados.length; nex ++) {
            if(hasNumber(dados[ind].Debito) && hasNumber(dados[nex].Credito)){
              if((dados[ind].Debito ===  dados[nex].Credito) ){
                dados[ind].Conciliado = "Talvez"
                dados[nex].Conciliado = "Talvez"
                if(compararString(dados[ind].Historico, dados[nex].Historico)){
                  dados[ind].Conciliado = "Sim"
                  dados[nex].Conciliado = "Sim"
                } 
              break;
            } else {
              if(compararString(dados[ind].Historico, dados[nex].Historico) && (dados[ind].Conta === dados[nex].Conta)){
                dados[ind].Conciliado = "Sim"
                dados[nex].Conciliado = "Sim"
                const desc = dados[nex].Credito - dados[ind].Debito
                dados[ind].DescontoSugerido =  (desc > (dados[nex].Credito * 0.10)) ? "0.00" : desc.toFixed(2)
              }
            }
          } else {
            
            if(hasNumber(dados[ind].Credito) && hasNumber(dados[nex].Debito)){
              if((dados[ind].Credito ===  dados[nex].Debito) ){
                dados[ind].Conciliado = "Talvez"
                dados[nex].Conciliado = "Talvez"
                if(compararString(dados[ind].Historico, dados[nex].Historico)){
                  dados[ind].Conciliado = "Sim"
                  dados[nex].Conciliado = "Sim"
                }
              break;
            } else {
              if(compararString(dados[ind].Historico, dados[nex].Historico) && (dados[ind].Conta === dados[nex].Conta)){
                dados[ind].Conciliado = "Sim"
                dados[nex].Conciliado = "Sim"
                const desc = dados[ind].Credito - dados[nex].Debito
                dados[nex].DescontoSugerido =  (desc > (dados[ind].Credito * 0.15)) ? "0.00" : desc.toFixed(2)
              }
            }
          } 
          }
        } 
      }
    }
  }


  const hasNumber = (myString) => {
    return /\d/.test(myString);
  }
  const compararString = (primeira, segunda) =>{
    if(hasNumber(primeira) && hasNumber(segunda)){
      if(primeira.length > segunda.length){
        const prov = segunda.split(" ");
        return(primeira.includes(parseInt(prov[prov.length - 1])))
      } else{
        const prov = primeira.split(" ");
        return(segunda.includes(parseInt(prov[prov.length - 1])))
      }
    }
    return false
  }

  const getActiveContente = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className="centralizar">
            <div className="UploadBox" >
            <InputExcel title="Conciliação Fortes"
            setFiles={setFiles} 
            />
            <div className="LadoDireito">
              {files !== undefined ?
                <div className={`InfoProgress`}>
                    <div style={{marginBottom: '20px', marginRight: '5px'}}>
                        {!isLoading && <CheckCircleIcon sx={{ color: '#5049CC' }} size={90} />}
                    </div>
                    <div>
                        <div className={`ArquivosProntos  ${!isLoading && "Subir"}`}>
                            { isLoading ? 'Aguarde, os dados estão sendo analisados' : 'Processo finalizado, pode prosseguir'}
                        </div> 
                        {isLoading && <BorderLinearProgress />}
                    
                    </div>
                </div> : ''}

              {files === undefined || isLoading  ? "" : 
                <div className="BotaoContinuar">
                  <button id="continueButton"
                onClick={()=>{setActiveStep(activeStep+1)}}>
                <h3>Continuar</h3> <ArrowForwardIcon size={18}/>
              </button>
              </div>
              }
            </div>
          </div>
          </div>
        );
        case 1:
          return (
            <Tabela nomeEmpresa={nomeEmpresa}
              allData={allData}
              dadosFiltrados={dadosFiltrados}
              setDadosFiltrados={setDadosFiltrados}
            />
          )
      default:
        return (
          <div>nada pra ver</div>
        )
    }
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 7,
    borderRadius: 5,
    width: 283,
    marginBottom: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#5049CC' : '#5049CC',
    },
  }));

  const saberColuna = (coluna) => {
    for(var ind = 0; ind < colunasDados.length; ind ++){
      if(coluna === colunasDados[ind])
        return ind;
    }
    return 0;
  }

  const saberLinhaColuna = (dados) => {
    for(var ind = 0; ind < 10; ind ++){
      if(dados[ind][0] === "Data")
        return ind;
    }
    return 3;
  }

  useEffect(() => {
    if (files !== undefined) {
        var reader = new FileReader(); 
        setIsLoading(true)
        reader.readAsBinaryString(files[0]);
        reader.onload = function(e) {  
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: "binary"});
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parseData = XLSX.utils.sheet_to_json(sheet, 
              {header: 1});
            colunasDados = parseData[saberLinhaColuna(parseData)];
            dadosExcel(parseData) 
            var empresa = parseData[1][0].split("Empresa: ")
            setNomeEmpresa(empresa[1])
              //dadosSped(linesResult)
          }
    }
  }, [files])



  const ExcelDateToJSDate = (date) => {
    let converted_date = new Date(Math.round((date - 25569) * 864e5));
    converted_date = String(converted_date).slice(4, 15)
    date = converted_date.split(" ")
    let day = parseInt(date[1]) === 31 ? 1 : parseInt(date[1]) + 1;
    let month = date[0];
    month = parseInt(date[1]) === 31 ? 
    parseInt("JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1) + 1 : 
    "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1
    if (month.toString().length <= 1)
        month = '0' + month
    let year = date[2];
    return String((day < 10 ? "0" : "") + day + '/' + month + '/' + year.slice(2, 4))
}

  return (
    <div className="App">
      {getActiveContente()}
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {activeStep === 0 ? '' :
            <div className="BotaoVoltar">
              <button id="backButton"
                onClick={() => { setActiveStep(activeStep - 1) }}>
                <h4>Voltar</h4>
              </button>
            </div>}
        </div>
    </div>
  );
}

export default ImportarExcelFts;
