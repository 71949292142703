import React, {useRef, useState} from 'react'


import Lottie, {LottieRefCurrentProps} from 'lottie-react';
import animationData from '../files/AnimationContact.json'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import emailjs from '@emailjs/browser'

import { TextField, TextareaAutosize } from '@mui/material';

const ContactSection = ( ) => {
    const animationRef = useRef()

    const [nome,setNome]= useState("")
    const [telefone,setTelefone]= useState("")
    const [email,setEmail]= useState("")
    const [mensagem,setMensagem]= useState("")

    const mandarEmail = () => {

        if(nome === "" || telefone === "" || email === "" || mensagem === ""){
            alert("Preencha todos os campos")
            return
        }

        const templateParams ={
            from_name: nome,
            message: mensagem,
            telefone: telefone,
            email: email
        }

        emailjs.send("service_6cciu3c", "template_3cpmwhm", templateParams, "VG7Td7UefAc_PM0tR")
        .then((response)=>{
            alert("Agradecemos o contato, email enviado com sucesso!")
            setNome("")
            setEmail("")
            setTelefone("")
            setMensagem("")
        }, (err) => {
            alert("Serviço indisponível no momento, por favor tente mais tarde.")
        }
        )
    }

  return (
    <div className='contactSection'>
        <div className='container' id='contato'>
        <div className='esquerdaContato'>
            <div className='animacaoContato'>
                <Lottie lottieRef={animationRef} animationData={animationData}/>
            </div>
        </div>
        <div className='direitaContato'>
            <h2>Entre em Contato</h2>
            <div className='separalinhacontato'>
            </div>
            <p>Gostou? Quer Tirar Dúvidas? Está com problemas? Basta preencher o formulário abaixo.</p>
            
            <TextField label="Nome/Empresa" value={nome} onChange={(e) => setNome(e.target.value)} variant="standard" color="secondary"  fullWidth />
            <div className='espaco'/>
            <TextField label="Telefone" value={telefone} onChange={(e) => setTelefone(e.target.value)} variant="standard" color="secondary"  fullWidth />
            <div className='espaco'/>
            <TextField label="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} variant="standard" color="secondary"  fullWidth />
            <div className='espaco'/>
            <TextField label="Mensagem" value={mensagem} onChange={(e) => setMensagem(e.target.value)} variant="standard" color="secondary"  fullWidth />
            <div className='espaco'/>
            <div className='espaco'/>
            <div className='botaoContato' >
              <span className='btn' onClick={mandarEmail}>Enviar <SubdirectoryArrowRightIcon style={{marginBottom: "-7px", marginLeft: "5px"}}/> </span>
            </div>
        </div>
        </div>
    </div>
  )
}

export default ContactSection